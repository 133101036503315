import React, { useEffect, useReducer, useState } from "react";
import SideNav from "../../components/SideNav";
import Paywall from "./TimeTrackingList/Paywall";
import { User } from "../../pages/HomePage/Login";
import TimeTracking from "./TimeTrackingList";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";

const initialTabStatus = {
    time_tracker: true,
    config: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE":
            state[action.tab] = true;
            return { ...state };
        default:
            return state;
    }
};

TimeTracking.propTypes = {openUpdateModalHandler: PropTypes.any};
const AssetTrackingTool = ({ history, location }) => {
    const [tabs, dispatch] = useReducer(reducer, initialTabStatus);
    const [queryParams, setQueryParams] = useState("");
    const [isTimeTrackerPaywall, setIsTimeTrackerPaywall] = useState(false);

    const handleUpdateTab = (tab) => {
        dispatch({ type: "UPDATE", tab: tab });
    };

    useEffect(() => {
        setIsTimeTrackerPaywall(User.showTimeTrackerPaywall());
        const searchParams = new URLSearchParams(location.search);
        const paramValue = searchParams.get("model");
        setQueryParams(paramValue);
    }, []);

    return (
        <React.Fragment>
            <div className="msmcontainer dashboard-container">
                <div className="row d-flex">
                    <SideNav />

                    <div className="content-wrap asset-tracking-wrap risk-assessment">
                        <div className="title-tab-wrap">
                            <h1>
                                <i
                                    style={{ paddingInlineStart: "5px", paddingRight: "5px" }}
                                    className="fa fa-warehouse"
                                ></i>
                                <b>Time Tracking Management</b>
                            </h1>
                            {isTimeTrackerPaywall ? (
                                <Paywall />
                            ) : (
                                <>
                                    <ul className="nav nav-tabs">
                                        <li
                                            className={queryParams ? "" : "active"}
                                            onClick={(e) => {
                                                handleUpdateTab("time-tracking");
                                            }}
                                        >
                                            <a data-toggle="tab" href="#time-tracking">
                                                Time Tracking Management
                                            </a>
                                        </li>
                                    </ul>
                                </>
                            )}
                        </div>
                        { !isTimeTrackerPaywall &&
                            <div className="tab-content">
                                <div
                                    id="time_tracker"
                                    className={
                                        !queryParams ? "tab-pane fade in active" : "tab-pane fade in"
                                    }
                                >
                                    <TimeTracking openUpdateModalHandler={setIsTimeTrackerPaywall} />
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(AssetTrackingTool);
