import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from '@mui/material'; 
import SearchIcon from "../../../../assets/fonts/Search.svg";
import _ from "lodash";

export default function MultiSelect(props) {
    const { show, closeup, details, rightSide, header } = props;
    const [checked, setChecked] = useState([]);
    const [checkedRight, setCheckedRight] = useState([]);
    const [right, setRight] = useState([]);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState();
    const [selectedSearch, setSelectedSearch] = useState();

    useEffect(() => {  
        let ids = intersection(details, rightSide)
        setRight(ids);
        details && setData(not(details, rightSide));
    }, [show])

    const handleStatusClose = () => {
        closeup("", "group");
        setRight([])
        setData(details)
    };

    function not(a, b) {
        return a.filter((value) => b.indexOf(value.identifier) === -1);
    }

    function intersection(a, b) { 
        return a.filter((value) => b.indexOf(value.identifier) !== -1);
    }

    const handleToggleAll = (value, isChecked) => {
        if (isChecked) {
            let newValues = _.map(value, "identifier");
            setChecked([...newValues]);
        } else {
            setChecked([]);
        }
    }

    const handleToggleAllRight = (value, isChecked) => {
        if (isChecked) {
            let newValues = _.map(value, "identifier");
            setCheckedRight([...newValues]);
        } else {
            setCheckedRight([]);
        }
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    }

    const handleToggleRight = (value) => () => {
        const currentIndex = checkedRight.indexOf(value);
        const newChecked = [...checkedRight];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setCheckedRight(newChecked);
    }

    const handleCheckedRight = () => {
        let newChecked = intersection(data, checked);
        setRight([...right, ...newChecked]);
        setData(not(data, checked));
        setChecked([]);
    }

    const handleCheckedLeft = () => { 
        let newChecked = intersection(right, checkedRight);
        setData([...data, ...newChecked]);
        setRight(not(right, checkedRight));
        setCheckedRight([]);
    } 

    const updateDetails = () => {
        closeup(right);
        setRight([]);
    }

    return (
        <Modal
            bsSize="lg"
            show={show}
            onHide={(e) => {
                handleStatusClose();
            }}
            className="qr-modal-wrap add-task-modal pf-model Activate multi-select"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Add & Modify {header}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex">
                    <div className="transfer-list">
                        <div className="unselected-users">
                            <h4>Unselected {header}</h4>
                            <span class="badge badge-light">{Number(data.length)}</span>
                            <input
                                type="text"
                                class="form-control assessment-name"
                                placeholder="Search"
                                aria-label="Username"
                                id="asset-name2" 
                                name="name"
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                                value={search}
                            />
                            <img className="search-icon" src={SearchIcon} alt="" />
                        </div>
                        <div className="users-list">
                            <ul>
                                {data.length != 0 &&
                                    <div className="select-all">
                                        <input type="checkbox" id="" value="1"
                                            checked={data.length === checked.length && checked.length !== 0}
                                            onClick={(e) => { handleToggleAll(data, e.target.checked) }}
                                        />
                                        Select All
                                    </div>
                                }
                                <div className="users-data">
                                    {
                                        data.filter((item) => new RegExp(search, 'i').test(item.full_name))
                                            .map((val, i) => (
                                                <div className="users">
                                                    <input type="checkbox" id="" value={val.identifier} onClick={handleToggle(val.identifier)} checked={checked.indexOf(val.identifier) !== -1} />
                                                    {val.full_name}
                                                </div>
                                            ))
                                    }
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="move-btn">
                        <div className="selected-btns mb-2">
                            <button type="button" onClick={(e) => { handleCheckedRight() }}>&gt;</button>
                        </div>
                        <div className="unselected-btn">
                            <button type="button" onClick={(e) => { handleCheckedLeft() }}>&lt;</button>
                        </div>
                    </div>
                    <div className="transfer-list">
                        <div className="selected-users">
                            <h4>Selected {header}</h4>
                            <span class="badge badge-light">{Number(right.length)}</span>
                            <input
                                type="text"
                                class="form-control assessment-name"
                                placeholder="Search"
                                aria-label="Username"
                                id="asset-name2" 
                                name="name"
                                onChange={(e) => {
                                    setSelectedSearch(e.target.value);
                                }}
                                value={selectedSearch}
                            />
                            <img className="search-icon" src={SearchIcon} alt="" />
                        </div>
                        <div className="users-list">
                            <ul>
                                {right.length != 0 &&
                                    <div className="select-all">
                                        <input type="checkbox" id="" value="1"
                                            checked={right.length === checkedRight.length && checkedRight.length !== 0}
                                            onClick={(e) => { handleToggleAllRight(right, e.target.checked) }}
                                        />
                                        Select All
                                    </div>
                                }
                                <div className="users-data">
                                    {
                                        right.filter((item) => new RegExp(selectedSearch, 'i').test(item.full_name))
                                            .map((val, i) => (
                                                <div className="users">
                                                    <input type="checkbox" id="" value={val.identifier} onClick={handleToggleRight(val.identifier)} checked={checkedRight.indexOf(val.identifier) !== -1} />
                                                    {val.full_name}
                                                </div>
                                            ))
                                    }
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="submit-btns">
                    <Button className="btn-cancel" onClick={(e) => { handleStatusClose() }}>Cancel</Button>
                    <Button variant="contained" className="btn-update" onClick={(e) => { updateDetails() }}>
                        Update
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
