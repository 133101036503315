import React, { useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { subscribeToUploadStatus } from '../../../../channels/upload_status_channel';
import { User } from '../../../HomePage/Login';

export default function FileUploadComponent(props) {
  const { show, closepopup, details, parentId, onUploadSuccess } = props;

  useEffect(() => {
    toast.options = {
      "preventDuplicates": true
    }
  })

  const handleDragOver = (e) => {
    e.preventDefault();
    var dropArea = document.getElementById('dropArea');
    dropArea.classList.add('dragover');
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const items = e.dataTransfer.items;

    $('.drag_folder_upload').show();
    $('.dropArea').hide();
    uploadFiles(items)
    $('.drag_folder_upload--close').show();
  };

  function initializeUploadStatus(totalFiles) {
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    let formData = new FormData();

    formData.append('total_files', totalFiles);
    formData.append('user_id', user.id);

    return new Promise((resolve, reject) => {
      $.ajax({
        url: 'upload_statuses',
        type: 'POST',
        data: formData,
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        processData: false,
        contentType: false,
        success: function (response) {
          User.currentBulkUploadId = response.data.upload_status_id;
          subscribeToUploadStatus(user.id, response.data.upload_status_id);
          resolve(response.data.upload_status_id);
        },
        error: function (error) {
          reject(error);
        }
      })
    })
  }

  function uploadFiles(dataTransferItems) {
    let files = [];
    let entriesPromises = [];

    for (let it of dataTransferItems) {
      entriesPromises.push(
        traverseFileTreePromise(it.webkitGetAsEntry(), '', files)
      );
    }
    let user = localStorage.getItem('user');
    user = JSON.parse(user);

    Promise.all(entriesPromises).then(() => {
      const totalFiles = countFiles(files);
      initializeUploadStatus(totalFiles).then(uploadStatusId => {
        handleFileAndFolderUploads(files, parentId, uploadStatusId, totalFiles);
      })
    })
  }

  function countFiles(entries) {
    let totalFiles = 0;

    function traverse(entries) {
      entries.forEach(entry => {
        if (entry.folder_name) {
          traverse(entry.sub_folder);
        } else {
          totalFiles += 1;
        }
      });
    }

    traverse(entries);
    return totalFiles;
  }

  function handleFileAndFolderUploads(entries, parentId, uploadStatusId, totalFiles) {
    let files = 0
    const uploadPromises = entries.map(entry => {
      if (entry.folder_name) {
        return createFolder(entry, parentId, uploadStatusId).then(response => {
          return handleFileAndFolderUploads(entry.sub_folder, response.parent_id, uploadStatusId, totalFiles);
        })
      } else if (entry.file_buffer) {
        const file = new File([entry.file_buffer], entry.file_name);
        files++;

        if (files == totalFiles) {
          toast.success("Files are being uploaded. Now it's safe to leave this page.");
        }

        return uploadFile(file, entry.relative_path, parentId, uploadStatusId)
      }
    })

    return Promise.all(uploadPromises);
  }

  function createFolder(entry, parentId, uploadStatusId) {
    return new Promise((resolve, reject) => {
      let user = localStorage.getItem('user');
      user = JSON.parse(user);

      const folderFormData = new FormData();
      folderFormData.append('folder_name', entry.folder_name);
      folderFormData.append('relative_path', entry.relative_path);
      folderFormData.append('parent_id', parentId);
      folderFormData.append('is_drag_folder', true);
      folderFormData.append('user_id', user.id)
      folderFormData.append('upload_status_id', uploadStatusId)

      $.ajax({
        url: `/company_documents/${user.company_id}/upload`,
        type: 'PATCH',
        data: folderFormData,
        processData: false,
        contentType: false,
        success: function(response) {
          console.log('Folder created: ', entry.folder_name);
          resolve(response);
        },
        error: function (error) {
          console.error('Error creating folder:', error);
          reject(error);
        }
      });
    })
  }

  function uploadFile(file, relativePath, parentId, uploadStatusId) {
    const totalSize = file.size;
    const concurrentUploads = 3
    let chunkStart = 0;
    let activeUploads = 0;
    const chunkSize = 1024 * 1024;

    function uploadNextChunk() {
      while (chunkStart < totalSize && activeUploads < concurrentUploads) {
        activeUploads++;
        const nextChunkSize = Math.min(chunkSize, totalSize - chunkStart);
        uploadChunk(file, chunkStart, nextChunkSize, totalSize, file.name, relativePath, parentId, uploadStatusId, () => {
          activeUploads--;
          uploadNextChunk();
        });
        chunkStart += nextChunkSize;
      }

      if (chunkStart >= totalSize && activeUploads === 0) {
        console.log('File uploaded successfully'); // TODO: Use toastr instead
        console.log(file)
      }
    }

    uploadNextChunk();
  }

  function uploadChunk(file, chunkStart, chunkSize, totalSize, fileName, relativePath, parentId, uploadStatusId, callback) {
    const chunk = file.slice(chunkStart, chunkStart + chunkSize);
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    const formData = new FormData();
    formData.append('chunk', chunk, fileName);
    formData.append('relativePath', relativePath);
    formData.append('chunkStart', chunkStart);
    formData.append('totalSize', totalSize);
    formData.append('is_drag_folder', true);
    formData.append('parent_id', parentId);
    formData.append('user_id', user.id);
    formData.append('upload_status_id', uploadStatusId);

    $.ajax({
      url: `/company_documents/${user.company_id}/upload`,
      type: 'PATCH',
      data: formData,
      processData: false,
      contentType: false,
      dataType: 'json',
      success: function (data, status) {
        callback();
        if (status == "success") {
          $('#upload_docs').modal('hide');
          $('.success_message').text('Folder was uploaded successfully').show();

          if (typeof onUploadSuccess === 'function') {
            console.log('Upload file successfully.')
            // onUploadSuccess();
          }
        } else {
          alert("Something went wrong");
        }
      }
    })
  }

  function traverseFileTreePromise(item, path = '', folder) {
    return new Promise(resolve => {
      if (item.isFile) {
        item.file(file => {
          let reader = new FileReader();
          reader.onloadend = () => {
            folder.push({ file_buffer: reader.result, file_name: file.name, relative_path: path + file.name });
            resolve();
          }
          reader.readAsArrayBuffer(file)
        });
      } else if (item.isDirectory) {
        let dirReader = item.createReader();
        dirReader.readEntries(entries => {
          let entriesPromises = [];
          let sub_folder = [];
          folder.push({ folder_name: item.name, relative_path: path + item.name + '/', sub_folder: sub_folder });

          for (const entry of entries)
            entriesPromises.push(
              traverseFileTreePromise(entry, path + item.name + "/", sub_folder)
            );

          Promise.all(entriesPromises).then(() => resolve());
        });
      }
    })
  }

  // function getFilesDataTransferItems(dataTransferItems) {
  //   function traverseFileTreePromise(item, path = "", folder) {
  //     return new Promise(resolve => {
  //       if (item.isFile) {
  //         item.file(file => {
  //           let reader = new FileReader();
  //           reader.onloadend = () => {
  //             folder.push({ file_path: reader.result, file_name: file.name });
  //             resolve(file);
  //           }
  //           reader.readAsDataURL(file)
  //         });
  //       } else if (item.isDirectory) {
  //         let dirReader = item.createReader();
  //         dirReader.readEntries(entries => {
  //           let entriesPromises = [];
  //           let sub_folder = [];
  //           folder.push({ folder_name: item.name, sub_folder: sub_folder });
  //           for (let entry of entries)
  //             entriesPromises.push(
  //               traverseFileTreePromise(entry, path || "" + item.name + "/", sub_folder)
  //             );
  //           resolve(Promise.all(entriesPromises));
  //         });
  //       }
  //     });
  //   }

  //   let files = [];
  //   return new Promise((resolve, reject) => {
  //     let entriesPromises = [];
  //     for (let it of dataTransferItems)
  //       entriesPromises.push(
  //         traverseFileTreePromise(it.webkitGetAsEntry(), null, files)
  //       );
  //     Promise.all(entriesPromises).then(entries => {
  //       resolve(files);
  //     });
  //   });
  // }

  return (
    <Modal
      show={show}
      onHide={(e) => {
        closepopup();
      }}
      className="qr-modal-wrap add-task-modal pf-model docs-model"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="pf-model-header">
            <p className="pf-model-title">Bulk Upload</p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div class="dropArea" id="dropArea" onDragOver={handleDragOver} onDrop={handleDrop}>
            <div class="drag_and_drop">
              <i class="fas fa-download fa-3x"></i>
              <span>DRAG AND DROP HERE</span>
            </div>
          </div >
          <div className="span drag_folder_upload" style={{display:"none", fontWeight:"bold", fontSize:"17px", color:"green"}}>
            Upload in progress, please wait!
          </div>
        </div>
      </Modal.Body>
    </Modal >
  );
}
