import React, { useEffect, useReducer, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import Paywall from "./Paywall";

const initialTabStatus = {
    inventoryList: true,
    archived: false,
    orderRequest: false,
    backOrder:false,
    orderInProgress: false,
    orderHistory: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE":
            state[action.tab] = true;
            return { ...state };
        default:
            return state;
    }
};

const TimeTracking = ({ history, openUpdateModalHandler }) => {

    return (
        <React.Fragment>
            <div className="msmcontainer dashboard-container">
                <div className="row d-flex">

                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(TimeTracking);
