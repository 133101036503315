import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal'; // Ensure this path is correct
import { User } from 'pages/HomePage/Login'; // Assuming User is imported correctly

const AccountStatus = (props) => {
    const [subscriptionStatus, setSubscriptionStatus] = useState(null); // To store subscription status
    const [loading, setLoading] = useState(true); // To manage loading state
    const [isModalVisible, setModalVisible] = useState(false); // Modal visibility state
    const [portalLink, setPortalLink] = useState('');

    useEffect(() => {
        // Fetch the subscription ID from User object
        const subscriptionId = User.companyInfo.external_id;
        console.log('Subscription ID:', subscriptionId);

        if (subscriptionId) {
            axios.get(`/api/v2/subscriptions/${subscriptionId}`)
                .then(response => {
                    console.log('API Response:', response.data);
                    const subscriptionDataTemp = JSON.parse(response.data.data);
                    const subscriptionData = subscriptionDataTemp[0];
                    // Assuming subscriptionData has the correct structure

                    if (subscriptionData && subscriptionData.subscription && subscriptionData.subscription.state) {
                        const subscriptionState = subscriptionData.subscription.state;
                        setSubscriptionStatus(subscriptionState);
                        debugger;
                        console.log('Subscription State:', subscriptionState);

                        // Show modal if the status is unpaid
                        if (subscriptionState === 'unpaid' || subscriptionState === 'on_hold') {
                        
                            setPortalLink(response.data.portal_link.toString());
                            
                            setModalVisible(true);
                        }
                    } else {
                        console.error('Unexpected response structure:', response.data);
                    }
                })
                .catch(error => console.error('Error fetching subscription status:', error))
                .finally(() => {
                    setLoading(false);
                });
        } else {
            console.error('Invalid subscription ID');
            setLoading(false);
        }
    }, []); // Empty dependency array means this effect runs once after the initial render

    if (loading) {
        return <div>Loading...</div>; // Display a loading indicator while fetching data
    }

    return (
        <div>
            <div style={{ color: 'rgb(240, 173, 78)', backgroundColor: 'rgba(240, 173, 78, 0.3)', display: 'flex', alignItems: 'center', padding: '11px 8px', float: 'left', marginRight: '16px' }}>
                {subscriptionStatus === 'trialing' ? (
                    <span>
                        <span>Welcome, you are currently in our free trial </span>
                        {/* <a style={{color: "#f0ad4e", fontWeight: "600", textDecoration: "underline", lineHeight: "41px !important"}} href="/company_profile/billing_page" className="waves-effect waves-light">Pay Now</a> */}
                    </span>
                ) : subscriptionStatus === 'past_due' ? (
                    <span>
                        <span>Your subscription is more than 30 days past due. Your company will not be able to access SafetyLoop soon </span>
                        <a style={{color: '#f0ad4e', fontWeight: '600', textDecoration: 'underline', lineHeight: '41px !important'}} href='/company_profile/billing_page' className='waves-effect waves-light'>Pay Now</a>
                    </span>
                ) : subscriptionStatus === 'unpaid' ? (
                    <span>
                        <span style={{fontSize:'14px'}}>Your subscription is more than 45 days past due. Your company will not be able to access SafetyLoop soon </span>
                        <a style={{color: '#f0ad4e', fontWeight: '600', textDecoration: 'underline', lineHeight: '41px !important'}} href='/company_profile/billing_page' className='waves-effect waves-light'>Pay Now</a>
                    </span>
                ) : subscriptionStatus === 'unpaid' ? (
                    <span>
                        <span style={{fontSize:'14px'}}>Your subscription is more than 45 days past due. Your company will not be able to access SafetyLoop soon </span>
                        <a style={{color: '#f0ad4e', fontWeight: '600', textDecoration: 'underline', lineHeight: '41px !important'}} href='/company_profile/billing_page' className='waves-effect waves-light'>Pay Now</a>
                    </span>
                ) : (
                    <span>
                        <span style={{ marginRight: '0px' }}>Active</span>
                        {/* {User.role === 'Admin' &&
                            (<a style={{ color: '#f0ad4e', fontWeight: '600', textDecoration: 'underline', lineHeight: '41px !important' }} href='/company_profile/billing_page' className='waves-effect waves-light'>Manage Billing</a>)} */}
                    </span>
                )}
            </div>
            <Modal isVisible={isModalVisible}>
                <img src="https://www.safetyloop.com/New-logo.svg" alt="SL Logo" className="modal-logo" />
                <div className="modal-message">
                    <h2>Subscription Overdue - Action required</h2>
                    <p>Your subscription is more than <span style={{ textDecoration: 'underline' }}>45 days</span> past due. Your company will not be able to access the admin side of SafetyLoop until payment is received.</p>
                    <p>We value safety and understand its importance. Therefore, the mobile entry side for forms and other functionalities will remain accessible for the next 15 days. After this period, access will be suspended unless payment in full is received. </p>
                    <a style={{color: '#f0ad4e', fontWeight: '600', textDecoration: 'underline', lineHeight: '41px !important'}} href={portalLink} target="_blank" className='waves-effect waves-light'>Pay Now</a> <i className="fa fa-external-link"></i>
                    <p style={{fontSize:'12px'}}>Click on the pay now, and "Update Payment Method" to add a new card on file.  You can also go into invoices, and pay each outstanding invoice directly using a Credit Card or ACH.</p>
                    
                    <div className="contact-info">
                        <p>If you have any questions, please contact SafetyLoop:</p>
                        <p>Phone: 833-444-9876</p>
                        <p>Email: support@safetyloop.com</p>
                        {/* <a style={{ color: '#f0ad4e', fontWeight: '600', textDecoration: 'underline', lineHeight: '41px !important', display: 'inline-block', marginTop: '10px' }} href={portalLink} target="_blank" className='waves-effect waves-light'>Access Detailed Billing</a> <i className="fa fa-external-link"></i> */}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AccountStatus;
