import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Collapse } from 'react-bootstrap';

import RightNavBar from './RightNavBar';
import HeaderLogo from './HeaderLogo';

//import NavSideBar from './NavSideBar';
//import backgroundImage from 'assets/images/sidebar-5.jpg';
//import '../../assets/styles/themes.scss';


class NavBar extends Component {


    constructor(props) {
        super(props);

        this.state = {
            isDragging: false,
            autoScrollInterval: null
        }

        this.stopAutoScroll = this.stopAutoScroll.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
    }

    componentDidMount() {
        document.body.addEventListener('dragstart', this.handleDragStart);
        document.body.addEventListener('dragend', this.handleDragEnd);
    }

    componentWillUnmount() {
        document.body.removeEventListener('dragstart', this.handleDragStart);
        document.body.removeEventListener('dragend', this.handleDragEnd);
        this.stopAutoScroll();
    }

    handleDragStart() {
        this.setState({ isDragging: true });
    }

    handleDragEnd() {
        this.setState({ isDragging: false });
        this.stopAutoScroll();
    }

    handleDragOver(event) {
        event.preventDefault();

        const scrollSpeed = 10;

        if (!this.state.isDragging) return;

        if (this.state.autoScrollInterval) return;

        const interval = setInterval(() => {
            if (!this.state.isDragging) {
                this.stopAutoScroll();
                return;
            }
            window.scrollBy(0, -scrollSpeed);
        }, 20);

        this.setState({ autoScrollInterval: interval });
    }

    stopAutoScroll() {
        if (this.state.autoScrollInterval) {
            clearInterval(this.state.autoScrollInterval);
            this.setState({ autoScrollInterval: null });
        }
    }

    handleDragLeave() {
        this.stopAutoScroll();
    }

    render() {
        let {
            location,
            backgroundColor,
            enableBackgroundImage,
        } = this.props;

        return (
            <nav className="msm-nav-header navbar navbar-default navbar-fixed-top" style={{ minHeight: "50px", height: "70px", padding: "0 15px", border: "none", boxShadow: "0 0 5px rgba(18,23,39,.5)", display: "flex" }} role="navigation" onMouseLeave={this.stopAutoScroll} onDragOver={this.handleDragOver} onDragLeave={this.handleDragLeave}>

                <HeaderLogo />
                <RightNavBar />

            </nav>
        )
    }
}

const mapStateToProps = state => ({
    enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
    backgroundColor: state.ThemeOptions.backgroundColor,
});

export default withRouter(
    connect(mapStateToProps)(NavBar)
);
