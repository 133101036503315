import React, { useEffect, useState, useReducer } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { Button, Switch } from "@mui/material";
import Select from "react-select";
import SideNav from "../../../components/SideNav";
import ArrowUp from "../../../assets/fonts/ArrowRight.svg";
import StockPrice from "../../../assets/fonts/StockPrice.svg";
import Notification from "../../../assets/fonts/Notification.svg";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";

const initialState = {
  name: "",
  inventory_type_id: "",
  status: "owned",
  supplier_id: "",
  serial_no: "",
  current_qty: "",
  low_stock_threshold: "",
  unit_price: "",
  inventory_location_id: "",
  last_order_date: "",
  quantity_unit: "unit",
  expiration_date: "",
  notes: "",
  reorder_status: false,
  notification_alert: "",
  notification_enabled: false,
  cost_per_day: "",
  rental_end_date: "",
  rental_start_date: "",
  quantity_tracking_disabled: false,
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "HANDLE INPUT CHANGE":
      return { ...state, [action.field]: action.payload };
    case "UPDATE":
      return { ...state, ...action.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

const CreateInventory = ({ history, match }) => {
  const [formValue, dispatch] = useReducer(formReducer, initialState);
  const [onSubmit, setSubmit] = useState(false);
  const [docs, setDocs] = useState();
  const [inventories, setInventories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [location, setLocation] = useState([]);
  const [imageFile, setImageFile] = useState("");
  const [disabled, setDisable] = useState(false);

  const id = match.params.id;
  const fileTypes = ["JPG", "PNG", "SVG", "JPEG"];

  useEffect(() => {
    generalInfo();
  }, []);

  useEffect(() => {
    getPageDetails(id);
  }, [suppliers]);

  const getPageDetails = async (id) => {
    try {
      window.scrollTo(0, 0);
      let resultObj = await axios.get(
        `${Application.api_url}/api/v2/inventories/${id}`
      );
      if (resultObj.status == 200) {
        let details = resultObj.data.data;
        setImageFile(details.image_url);
        dispatch({ type: "UPDATE", payload: details });
      }
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const generalInfo = async () => {
    let list = await axios.get(
      `${Application.api_url}/api/v2/common/inventory_base_data`
    );
    if (list.data && list.data.success == true) {
      setInventories(list.data.data.inventory_types);
      setSuppliers(list.data.data.suppliers);
      setLocation(list.data.data.inventory_locations);
    }
  };

  const handleTextChanges = (e) => {
    setDisable(false);
    dispatch({
      type: "HANDLE INPUT CHANGE",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelectChanges = (name, value) => {
    dispatch({ type: "HANDLE INPUT CHANGE", field: name, payload: value.id });
  };

  const handleSwitchChanges = (name, value) => {
    setDisable(false);
    dispatch({ type: "HANDLE INPUT CHANGE", field: name, payload: value });
  };

  const saveInfo = async () => {
    try {
      setSubmit(true);

      if (
        !formValue.name ||
        !formValue.inventory_type_id ||
        !formValue.status ||
        !formValue.supplier_id ||
        ((!formValue.current_qty || !formValue.low_stock_threshold || !formValue.unit_price) && formValue.quantity_tracking_disabled == false) ||
        !formValue.inventory_location_id
      ) {
        toast.info('Please fill all the required fields')
        return false;
      }

      if (formValue.status == "rented") {
        if(!formValue.cost_per_day || !formValue.rental_end_date || !formValue.rental_start_date){
          toast.info('Please fill all the required fields')
          return false
        };
      }

      if (formValue.notification_enabled && !formValue.notification_alert) {
          toast.info('Please fill all the required fields')
          return false
      }

      const formData = new FormData();
      formData.append("name", formValue.name);
      formData.append("inventory_type_id", formValue.inventory_type_id);
      formData.append("status", formValue.status);
      formData.append("supplier_id", formValue.supplier_id);
      formData.append("quantity_tracking_disabled", formValue.quantity_tracking_disabled);
      formData.append("quantity_unit", formValue.quantity_unit);
      formData.append("current_qty", formValue.current_qty == null ? "" : formValue.current_qty );
      formData.append("low_stock_threshold", formValue.low_stock_threshold == null ? "" : formValue.low_stock_threshold );
      formData.append("unit_price", formValue.unit_price == null ? "" : formValue.unit_price );
      formData.append("inventory_location_id", formValue.inventory_location_id);
      formData.append("serial_no", formValue.serial_no);
      formData.append("expiration_date", formValue.expiration_date);
      formValue.last_order_date && formData.append("last_order_date", formValue.last_order_date);
      notes && formData.append("notes", formValue.notes);
      docs && formData.append("image", docs);
      //formData.append("reorder_status", formValue.reorder_status);
      formData.append("notification_enabled", formValue.notification_enabled);
      formValue.notification_enabled &&
        formData.append("notification_alert", formValue.notification_alert);
      if (formValue.status == "rented") {
        formData.append("cost_per_day", formValue.cost_per_day);
        formData.append("rental_end_date", formValue.rental_end_date);
        formData.append("rental_start_date", formValue.rental_start_date);
      }

      let method = "POST";
      let apiUrl = `${Application.api_url}/api/v2/inventories`;

      if (id) {
        method = "PATCH";
        apiUrl = `${Application.api_url}/api/v2/inventories/${id}`;

        if (!docs && !imageFile) {
          formData.append("logo", "");
        }
      }

      await axios({
        method: method,
        url: apiUrl,
        data: formData,
      })
        .then((result) => {
          if (result.data && result.data.success === true) {
            toast.success(id ? "Updated Successfully" : `Created Successfully`);
            goBack();
          }
        })
        .catch((error) => {
          error.response.data.message.map((msg) => {
            toast.error(msg);
          });
        });
    } catch (err) {
      console.log("err===>", err);
    }
  };

  const goBack = () => {
    history.push("/inventory");
  };

  const handleFileChange = (file) => {
    setDocs(file);
    setImageFile(URL.createObjectURL(file));
  };

  const handleDelete = () => {
    setDocs("");
    setImageFile("");
  };

  return (
    <React.Fragment>
      <div className="msmcontainer dashboard-container">
        <div className="row d-flex">
          <SideNav />
          <div className="content-wrap add-inventory">
            <div className="title-card">
              <div className="title-content">
                <div>
                  <img
                    src={ArrowUp}
                    alt="back-arrow"
                    className="back-arrow"
                    onClick={(e) => {
                      goBack();
                    }}
                  />
                </div>
                <h4>
                  {!id && (
                    <i
                      style={{ paddingInlineStart: "5px", paddingRight: "5px" }}
                      className="fa fa-plus"
                    ></i>
                  )}
                  <b>{id ? "Update" : "Create"} Inventory</b>
                </h4>
              </div>
              <div>
                <Button
                  className="btn-cancel"
                  onClick={(e) => {
                    goBack();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-create"
                  variant="contained"
                  disabled={disabled}
                  onClick={(e) => {
                    saveInfo();
                  }}
                >
                  {id ? "Update" : "Create"}
                </Button>
              </div>
            </div>
            <div className="body-card">
              <div className="row">
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="fields">
                        <label className="label-name">
                          Inventory Name <text style={{ color: "red" }}>*</text>
                        </label>
                        <input
                          name="name"
                          id="name"
                          className="new-input"
                          value={formValue.name}
                          onChange={(e) => {
                            handleTextChanges(e);
                          }}
                        />
                        {onSubmit && !formValue.name && (
                          <p className="required-fields">
                            Please enter the name
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="fields">
                        <label className="label-name">
                          Inventory Type <text style={{ color: "red" }}>*</text>
                        </label>
                        <Select
                          options={inventories}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={inventories.filter(
                            (val) => val.id == formValue.inventory_type_id
                          )}
                          onChange={(event, value) => {
                            handleSelectChanges("inventory_type_id", event);
                          }}
                        />
                        {onSubmit && !formValue.inventory_type_id && (
                          <p className="required-fields mt-1">
                            Please select the inventory type
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="fields">
                        <label className="label-name">
                          Owned/Rented <text style={{ color: "red" }}>*</text>
                        </label>
                        <select
                          name="status"
                          id="status"
                          className="new-select"
                          value={formValue.status}
                          onChange={(e) => {
                            handleTextChanges(e);
                          }}
                        >
                          <option value="owned">Owned</option>
                          <option value="rented">Rented</option>
                        </select>
                        {onSubmit && !formValue.status && (
                          <p className="required-fields">Please select</p>
                        )}
                      </div>
                    </div>
                  </div>
                  {formValue.status == "rented" && (
                    <div className="row">
                      <div className="col-md-4">
                        <div className="fields">
                          <label className="label-name">
                            Cost per day <text style={{ color: "red" }}>*</text>
                          </label>
                          <input
                            type="text"
                            name="cost_per_day"
                            id="cost_per_day"
                            className="new-input"
                            value={formValue.cost_per_day}
                            onChange={(e) => {
                              handleTextChanges(e);
                            }}
                          />
                          {onSubmit && !formValue.cost_per_day && (
                            <p className="required-fields">
                              Please enter the cost
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="fields">
                          <label className="label-name">
                            Rental start date
                            <text style={{ color: "red" }}>*</text>
                          </label>
                          <input
                            type="date"
                            name="rental_start_date"
                            id="rental_start_date"
                            className="new-input"
                            value={formValue.rental_start_date}
                            onChange={(e) => {
                              handleTextChanges(e);
                            }}
                          />
                          {onSubmit && !formValue.rental_start_date && (
                            <p className="required-fields">
                              Please select the date
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="fields">
                          <label className="label-name">
                            Rental end date
                            <text style={{ color: "red" }}>*</text>
                          </label>
                          <input
                            type="date"
                            name="rental_end_date"
                            id="rental_end_date"
                            className="new-input"
                            value={formValue.rental_end_date}
                            onChange={(e) => {
                              handleTextChanges(e);
                            }}
                          />
                          {onSubmit && !formValue.rental_end_date && (
                            <p className="required-fields">
                              Please select the date
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="fields">
                        <label className="label-name">
                          Supplier <text style={{ color: "red" }}>*</text>
                        </label>
                        <Select
                          options={suppliers}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={suppliers.filter(
                            (val) => val.id == formValue.supplier_id
                          )}
                          onChange={(event, value) => {
                            handleSelectChanges("supplier_id", event);
                          }}
                        />
                        {onSubmit && !formValue.supplier_id && (
                          <p className="required-fields mt-1">
                            Please select supplier
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="fields">
                        <label className="label-name">
                          Barcode/Serial Number
                          <text style={{ color: "red" }}>*</text>
                        </label>
                        <input
                          name="serial_no"
                          id="serial_no"
                          className="new-input"
                          value={formValue.serial_no}
                          onChange={(e) => {
                            handleTextChanges(e);
                          }}
                        />
                        {onSubmit && !formValue.serial_no && (
                          <p className="required-fields">
                            Please enter the barcode/serial
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row d-flex align-item-center">
                      <p className="stock-price mb-0">
                        <img
                          src={StockPrice}
                          alt="stock price"
                          className="mr-2"
                        />
                        Stock and Price Details
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12 user-details">
                    <div className="row">
                      <div className="set-notification-alert">
                        <div className="first-col">
                          <Switch
                            checked={formValue.quantity_tracking_disabled}
                            onClick={(event) =>
                              handleSwitchChanges(
                                "quantity_tracking_disabled",
                                !formValue.quantity_tracking_disabled
                              )
                            }
                          />
                          <span className="text-alert">
                            Disable Quantity Tracking
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="fields">
                          <label className="label-name">
                            Current Quantity
                            { !formValue.quantity_tracking_disabled && <text style={{ color: "red" }}>*</text> }
                          </label>
                          <div className="current-qnt">
                            <select
                              name="quantity_unit"
                              id="quantity_unit"
                              className="new-select"
                              value={formValue.quantity_unit}
                              onChange={(e) => {
                                handleTextChanges(e);
                              }}
                              placeholder="All"
                            >
                              <option value="unit">Unit</option>
                              <option value="ounce">Ounce</option>
                              <option value="gallon">Gallon </option>
                              <option value="grams">Grams (g)</option>
                              <option value="yards">Yards (yd)</option>
                              <option value="feet">Feet (ft)</option>
                              <option value="liters">Liters (L)</option>
                              <option value="pounds">Pounds (lbs)</option>
                              <option value="tons">Tons( t)</option>
                              <option value="containers">Containers (cnt)</option>
                              <option value="drums">Drums(dr)</option>
                              <option value="boxes">Boxes (box)</option>
                              <option value="skids">Skids (sk)</option>
                            </select>
                            <input
                              name="current_qty"
                              id="current_qty"
                              className="new-input"
                              value={formValue.current_qty}
                              onChange={(e) => {
                                handleTextChanges(e);
                              }}
                            />
                          </div>
                          {onSubmit && !formValue.quantity_tracking_disabled && !formValue.current_qty && (
                            <p className="required-fields">
                              Please enter the quantity
                            </p>
                          )}
                          {onSubmit && !formValue.quantity_tracking_disabled && !formValue.quantity_unit && (
                            <p className="required-fields">
                              Please select the unit
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="fields">
                          <label className="label-name">
                            Low Stock Threshold
                            { !formValue.quantity_tracking_disabled && <text style={{ color: "red" }}>*</text> }
                          </label>
                          <input
                            name="low_stock_threshold"
                            id="low_stock_threshold"
                            className="new-input"
                            value={formValue.low_stock_threshold}
                            onChange={(e) => {
                              handleTextChanges(e);
                            }}
                          />
                          {onSubmit && !formValue.quantity_tracking_disabled && !formValue.low_stock_threshold && (
                            <p className="required-fields">
                              Please enter the low stock threshold
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="fields">
                          <label className="label-name">
                            Unit Price $ { !formValue.quantity_tracking_disabled && <text style={{ color: "red" }}>*</text> }
                          </label>
                          <input
                            name="unit_price"
                            id="unit_price"
                            className="new-input"
                            value={formValue.unit_price}
                            onChange={(e) => {
                              handleTextChanges(e);
                            }}
                          />
                          {onSubmit && !formValue.quantity_tracking_disabled && !formValue.unit_price && (
                            <p className="required-fields">
                              Please enter the unit price
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        {formValue.current_qty && formValue.unit_price && (
                          <div className="total-inventory-value">
                            <span className="total-inventory">
                              Total Inventory Value
                            </span>
                            <span className="amount">
                              $
                              {Number(formValue.current_qty) *
                                Number(formValue.unit_price)}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="fields">
                          <label className="label-name">
                            Location <text style={{ color: "red" }}>*</text>
                          </label>
                          <Select
                            options={location}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            value={location.filter(
                              (val) => val.id == formValue.inventory_location_id
                            )}
                            onChange={(event, value) => {
                              handleSelectChanges(
                                "inventory_location_id",
                                event
                              );
                            }}
                          />
                          {onSubmit && !formValue.inventory_location_id && (
                            <p className="required-fields mt-1">
                              Please select the location
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="fields">
                          <label className="label-name">
                            Last Order Date
                          </label>
                          <input
                            type="date"
                            name="last_order_date"
                            id="last_order_date"
                            max={new Date().toJSON().split("T")[0]}
                            className="new-input"
                            value={formValue.last_order_date}
                            onChange={(e) => {
                              handleTextChanges(e);
                            }}
                          />
                          {/* {onSubmit && !formValue.last_order_date && (
                            <p className="required-fields">
                              Please enter the last order date
                            </p>
                          )} */}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="fields">
                          <label className="label-name">
                            Expiration Date
                          </label>
                          <input
                            type="date"
                            name="expiration_date"
                            id="expiration_date"
                            className="new-input"
                            value={formValue.expiration_date}
                            min={new Date().toJSON().split("T")[0]}
                            onChange={(e) => {
                              handleTextChanges(e);
                            }}
                          />
                          {/* {onSubmit && !formValue.expiration_date && (
                            <p className="required-fields">
                              Please enter the expiration date
                            </p>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="set-notification-alert">
                        <div className="first-col">
                          <Switch
                            checked={formValue.notification_enabled}
                            onClick={(event) =>
                              handleSwitchChanges(
                                "notification_enabled",
                                !formValue.notification_enabled
                              )
                            }
                          />
                          <img src={Notification} alt="notification-icon" />
                          <span className="text-alert">
                            Set Notification Alert
                          </span>
                        </div>
                        {formValue.notification_enabled && (
                          <>
                            <span className="divider-line"></span>
                            <div className="third-col">
                              <div
                                className="schedule"
                                onClick={(event) =>
                                  handleSwitchChanges(
                                    "notification_alert",
                                    event.target.value
                                  )
                                }
                              >
                                <label className="label-name">
                                  Repeat Schedule
                                </label>
                                <div className="radio-items">
                                  <div className="radio-input">
                                    <input
                                      type="radio"
                                      id="daily"
                                      name="daily"
                                      value="daily"
                                      checked={
                                        formValue.notification_alert == "daily"
                                      }
                                    />
                                    <span htmlFor="daily">Daily</span>
                                  </div>
                                  <div className="radio-input">
                                    <input
                                      type="radio"
                                      id="weekly"
                                      name="weekly"
                                      value="weekly"
                                      checked={
                                        formValue.notification_alert == "weekly"
                                      }
                                    />
                                    <span htmlFor="weekly">Weekly</span>
                                  </div>
                                  <div className="radio-input">
                                    <input
                                      type="radio"
                                      id="monthly"
                                      name="monthly"
                                      value="monthly"
                                      checked={
                                        formValue.notification_alert == "monthly"
                                      }
                                    />
                                    <span htmlFor="monthly">Monthly</span>
                                  </div>
                                  <div className="radio-input">
                                    <input
                                      type="radio"
                                      id="quarterly"
                                      name="quarterly"
                                      value="quarterly"
                                      checked={
                                        formValue.notification_alert == "quarterly"
                                      }
                                    />
                                    <span htmlFor="quarterly">Quarterly</span>
                                  </div>
                                  <div className="radio-input">
                                    <input
                                      type="radio"
                                      id="annually"
                                      name="annually"
                                      value="annually"
                                      checked={
                                        formValue.notification_alert == "annually"
                                      }
                                    />
                                    <span htmlFor="annually">Annually</span>
                                  </div>
                                </div>
                              </div>
                                {onSubmit && !formValue.notification_alert && (
                                  <p className="required-fields">
                                    Please enter the notification alert
                                  </p>
                                )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-12 mt-4">
                    <div className="row">
                      <div className="set-notification-alert">
                        <div className="first-col">
                          <Switch
                            checked={formValue.reorder_status}
                            onClick={(event) =>
                              handleSwitchChanges(
                                "reorder_status",
                                !formValue.reorder_status
                              )
                            }
                          />
                          <span className="text-alert">Reorder Status</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="col-md-5">
                  <div>
                    <div className="create-information-text-area">
                      <label className="label-name">Notes/Comments</label>
                      <textarea
                        className="create-asset-textarea"
                        aria-label="empty textarea"
                        name="notes"
                        id="notes"
                        value={formValue.notes == "null" ? "" : formValue.notes}
                        onChange={(e) => {
                          handleTextChanges(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 logo-upload">
                    <label className="label-name">Attachment</label>
                    <FileUploader
                      handleChange={handleFileChange}
                      name="file"
                      label="Upload or drop files here"
                      className="drop-box"
                      types={fileTypes}
                    />
                    {imageFile && (
                      <div className="attachment-grid">
                        <div className="file-item">
                          <img
                            src={imageFile}
                            className="file-type"
                            alt="file"
                            style={{ width: "80px", height: "auto" }}
                          />
                          <button
                            className="delete-button"
                            onClick={() => handleDelete()}
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              transform: "translate(-145%, 5%)",
                              backgroundColor: "transparent",
                              border: "none",
                              cursor: "pointer",
                              padding: "0",
                            }}
                          >
                            <img
                              src="/packs/media/assets/fonts/Delete-62b3f74c182772359479dc3681fbd490.svg"
                              alt="Delete"
                              style={{
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateInventory);
