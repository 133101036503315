import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";

const HeaderLogo = () => {

  const [pageDetails, setPageDetails] = useState("")

  useEffect(() => {
    async function fetchData() {
      const result = await axios.get("/users/check_for_user");
      if (result && result.data) {
        setPageDetails(result.data);
      }
    }
    fetchData().then((r) => r);
  }, []);

  return (
    <>
      <div className="navbar-header" style={{ display: "block", padding: "14px 0px" }}>
        <Link to="/analytics-dashboard"><img alt="SafetyLoop Main" style={{ position: "fixed", width: "80px" }} src={"/New-logo.svg"} /></Link>
        <div className="offline-status">
          <span>
            <i aria-hidden="true" className="fa fa-wifi" style={{ "marginRight": "15px" }}></i>
            You are currently offline
            <a href="." style={{ marginLeft: "10px" }}>Refresh</a>
          </span>
        </div>
      </div>
      <div className='nav-company'>
        <img src={pageDetails && pageDetails.company_url ? pageDetails.company_url : '/Company.svg'} alt="company" />
        <p>{pageDetails && pageDetails.company && pageDetails.company.name}</p>
      </div>
      <div className='nav-select-arrow'><img alt="arrow" src={"ArrowDown.svg"} /></div>
      <div className='nav-group'>
        <img src='Nav-Group.svg' alt="group" />
        <p className='nav-group-option'>
          
          <select name="test" id="test" className='nav-group-select'>
            <option value="">Select</option>
          </select>
        </p>
      </div>
    </>
  )

};




export default HeaderLogo;





