import React, { useState } from "react";

import "../../assets/styles/Home/styles.scss";
import FeaturesSection from "../../components/Features/CommonComponents/FeaturesSection";
import FeaturesSection2 from "../../components/Features/CommonComponents/FeaturesSection2";

import Industry from "../../../javascript/assets/images/industry.svg";
import CORLogo from "../../../javascript/assets/images/corisowsib-hp.png";
import SliderImg from "../../../javascript/assets/images/slider-image4.png";
import FeatureHighlightSection from "./Components/FeatureHighlightSection";
import ParallaxSection from "./Components/ParallaxSection";
import NumberStatsSection from "./Components/NumberStatsSection";
import HomepageDemoSection from "./Components/HomepageDemoSection";
import CompaniesSliderSection from "./Components/CompaniesSliderSection";
import HomePageHeroSection from "./Components/HomePageHeroSection";

const Home = () => {
	return (
		<div className="Home-Page sl-home-wrapper">
			<HomePageHeroSection />
			<CompaniesSliderSection />
			<FeaturesSection
				headerText={"SafetyLoop Works With Industry Leaders"}
				subText={""}
				featureHighlights={["SafetyLoop seamlessly integrates contractors, subcontractors, vendors, and suppliers into a unified safety ecosystem, providing a holistic approach to safety management. This integration ensures complete oversight, allowing you to efficiently manage and monitor your safety management system with ease, and in real time.",
					"Operational efficiency and regulatory compliance are at your fingertips with SafetyLoop's tailored solutions. Specifically designed for safety leaders, our platform simplifies the complexities of safety management, putting an end to the constant chase for compliance. With SafetyLoop, you can now focus on what truly matters—proactive safety measures.",
					"	Take control of health and safety protocols with SafetyLoop, where empowerment meets precision. Partner with us to ensure end-to-end user risk management, creating a workplace that is not only more productive but fundamentally safer and more secure. At SafetyLoop, we are dedicated to putting the power of safety back into your hands."]}
				sectionBackground='dark'
				sectionAlign="image-right"
				featureImageSrc={Industry}
				ctaButtonTitle={"Explore all features"}
				ctaLink="/features"
			/>
			<FeaturesSection
				headerText={"SIMPLIFY YOUR PATH TO ANY ACCREDITATION"}
				subText={"Simplify your journey through the Accreditation process with SafetyLoop.  We streamline the process, ensuring your OHS prequalification is efficient and hassle-free.  Get on the right path with us."}
				featureHighlights={["SafetyLoop simplifies the complex process of preparing for COR®, ISO & WSIB’s Health & Safety Excellence qualification process, saving you time and effort.",
					"Our platform seamlessly integrates with your Health and Safety Management System (HSMS), ensuring a smooth COR® compliance journey.",
					"Benefit from our expertise and guidance to navigate OHS prequalification requirements with confidence."]}
				featureImageSrc={CORLogo}
				ctaButtonTitle="COR Academy"
				ctaLink="https://cor-academy.com"
			/>
			<FeaturesSection2
				headerText={"Tailor Your Safety System"}
				subText={"Unlock complete customization for your organization's safety processes with SafetyLoop. Our ALIGN-PLAN-EXECUTE model seamlessly adapts your safety system to your corporate safety culture."}
				featureHighlights={["Align: We invest the time to comprehend your corporate safety culture and expertly craft the entire digitized system to match your organization's specific needs.",
					"Plan: We strategically map out all workflows, communication processes, safety oversight, and responsibilities to harmonize with your existing safety system.",
					"We meticulously implement the agreed-upon rollout plan, providing step-by-step guidance and comprehensive support at every level of your organization.",
				  "In summary, SafetyLoop tailors a fully customized digitized safety management system to your exact specifications, delivers it as a complete turnkey solution, and ensures success through our dedicated support team. Your safety, your way, with our unwavering assistance."]}
				sectionBackground='#ECECEC'
				sectionAlign="image-right"
				featureImageSrc={SliderImg}
			/>
			<FeatureHighlightSection />
			<ParallaxSection />
			<NumberStatsSection />
			<HomepageDemoSection />
		</div >
	);
};

export default Home;
