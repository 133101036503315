import React from 'react'
import HeroSection from './CommonComponents/HeroSection'
import FeaturesSection from './CommonComponents/FeaturesSection'
import asset_mgmt from '../../../assets/images/asset1.png'
import asset2 from '../../../assets/images/asset2.png'
import asset3 from '../../../assets/images/asset3.png'
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection'
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection'

export default function AssetManagement() {
  return (
    <>
      <HeroSection heroText={"Optimized Asset Management"} subText={"Take control of your assets with SafetyLoop's comprehensive management tools. Streamline asset tracking, maintenance, and compliance within your safety eco-system."} />
      <FeaturesSection
        headerText={"Efficient Asset Tracking"}
        subText={"SafetyLoop simplifies the process of tracking and managing your assets. Gain real-time visibility and control over your valuable resources."}
        featureHighlights={["Track assets across multiple locations and projects seamlessly.", "Monitor asset status, maintenance schedules, and usage history.", "Receive automated alerts for maintenance and compliance tasks.", "Improve asset utilization and reduce downtime."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={asset_mgmt}
      />
      <FeaturesSection
        headerText={"Trackable Assets"}
        subText={"Tailor asset types to your organization's needs with SafetyLoop. Categorize and manage assets based on your unique criteria."}
        featureHighlights={["Create custom asset types to classify your assets effectively.", "Define attributes and properties for each asset type.", "Streamline asset management by grouping similar assets together.", "Easily identify and analyze asset performance and trends."]}
        featureImageSrc={asset2}
      />
      <FeaturesSection
        headerText={"Comprehensive Asset Management"}
        subText={"SafetyLoop offers a holistic approach to asset management, ensuring your assets are optimized for safety and efficiency."}
        featureHighlights={["Centralize all your assets within a unified platform for easy access.", "Enhance compliance by tracking asset-related documentation and certifications.", "Improve decision-making with data-driven insights on asset performance.", "Ensure accountability by assigning asset responsibilities to team members.", "Increase asset lifespan and reduce replacement costs through proactive maintenance."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={asset3}
      />
      <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
        <ParallaxSection />
      </div>
      <div className="features-homepage-wrapper">
        <HomepageDemoSection mergeTop={false} />
      </div>
    </>
  )
}
