import React, { useEffect } from "react";
import LoginFooter from "../pages/HomePage/LoginFooter";
import login from "../../javascript/assets/images/login.svg";
import Disclaimer from "./eula";
import {Link} from "react-router-dom";

const EULA = () => {
  useEffect(() => {
    const getUser = localStorage.getItem("user");
    console.log(JSON.parse(getUser));
  },[]);

  if(localStorage.user_eula_status == false){
    window.location.href = "/analytics-dashboard";
  }

  const onhandleAccept = (e) => {
    const getUser = localStorage.getItem("user");
    const user_id = localStorage.getItem("user_id");
    console.log("test",getUser)
    console.log("user_iduser_iduser_iduser_id",user_id)
    e.preventDefault();
    let userDetails = {
      eula_status: true,
      id:user_id
    };
    $(".loading").show();
    $.ajax({
      type: "PATCH",
      url: "/update_eula_status",
      dataType: "json",
      data: userDetails,
      error: function (error) {
        $(".loading").hide();
      },
      success: function (res) {
        console.log(res);
        window.location.href = "/analytics-dashboard"
      },
    });
  };

  const fetchOneSignalId = () => {
    return window.OneSignal.getUserId(function (userId) {
      return userId;
    });
  }


  const onhandleClose = (e) => {
    $(".loading").show();
    let oneSignalId;
    try {
      oneSignalId = fetchOneSignalId()
    } catch (error) {
      oneSignalId = '';
    }

    $.ajax({
      type: "GET",
      url: `/users/sign_out?push_token=${oneSignalId}`,
      dataType: "json",
      error: function (error) {
        console.log(error);
      },
      success: function (res) {
        window.location.href = '/';
      },
    });

  };

  return (
    <div className="main-wrap user-login-page">
      <div className="row new-login-page">
        <div className="home-container">
          {/* <div className="login-image col-md-6 col-sm-12">
            <div className="login">
              <img src={login} />
            </div>
          </div> */}
          <div class="modal show eula-modal" id="myModal" role="dialog">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body">
                  <Disclaimer />
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={(e) => onhandleClose(e)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-dismiss="modal"
                    onClick={(e) => onhandleAccept(e)}
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "5px" }}>
        <LoginFooter />
      </div>
    </div>
  );
};

export default EULA;
