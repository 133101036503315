import React, { useState } from "react";
import Select from "react-select";
import {
    currentYear,
    frequencyOptions,
    generateYearOptions,
    generateMonthOptionsData,
    defaultFilters,
} from "../../utils/util.js";

const initialState = {
    year: currentYear,
    frequency: "month",
    month: "",
    user: "",
};

// const FormsGroupByFilters = ({  onFilterSelect, onFilterReset  }) => {
const FormsGroupByFilters = ({
                                 formFilters,
                                 filters,
                                 onFilterSelect,
                                 onFilterReset,
                             }) => {
    const [fields, setFields] = useState(filters);

    const onFilterChange = (event, field) => {
        let params = generate_query_params(event.value, field);

        params.search = true;

        if (params.frequency == "month" && params.month != "") params.month = "";

        if (params.frequency == "week" && params.month == "")
            console.log("missing");
        else {
            onFilterSelect(params);
        }
    };

    const onResetFilter = () => {
        setFields(initialState);
        onFilterReset(initialState);
    };

    const generate_query_params = (value, field) => {
        setFields((prevState) => {
            return { ...prevState, [field]: value };
        });

        let params = { [field]: value };
        let data = Object.assign({}, fields, params);
        return data;
    };

    const renderMonthFilter = () => {
        return (
            <div>
                {fields["frequency"] === "week" ? (
                    <div className="show">
                        <label className="control-label">Month</label>
                        <Select
                            name="month"
                            value={fields["month"]}
                            onChange={(e) => onFilterChange(e, "month")}
                            options={generateMonthOptionsData()}
                            id="month"
                            clearable={false}
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    };

    return (
        

        <div className="forms-group-filter">
            
            <div className="row">
                <div className="col-xs-3 col-md-3 mt-4">
                    <label className="control-label">Form Type</label>
                    <Select
                        name="form_type_id"
                        value={fields["form_type_id"]}
                        onChange={(e) => onFilterChange(e, "form_type_id")}
                        options={formFilters["form_types"]}
                        id="form_type_id"
                        clearable={false}
                    />
                </div>

                <div className="col-xs-3 col-md-3 mt-4">
                    <label className="control-label">Group By</label>
                    <Select
                        name="filter"
                        value={fields["frequency"]}
                        onChange={(e) => onFilterChange(e, "frequency")}
                        options={frequencyOptions}
                        id="frequency"
                        clearable={false}
                        defaultValue={"-"}
                    />
                </div>
                <div className="col-xs-3 col-md-3 mt-4">
                    <label className="control-label">Year</label>
                    <Select
                        name="Year"
                        value={fields["year"]}
                        onChange={(e) => onFilterChange(e, "year")}
                        options={generateYearOptions()}
                        id="year"
                        clearable={false}
                    />
                </div>

                <div className="col-xs-3 col-md-3 mt-4">{renderMonthFilter()}</div>

                <div
                    className="col-xs-3 col-md-3 d-flex align-item-center justify-content-between"
                    style={{ marginTop: "20px" }}
                >
                    <div className="filter-button" id="home-reset-filter">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => onResetFilter(e)}
                        >
                            Reset Filter
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormsGroupByFilters;
