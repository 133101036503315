import React from 'react';
import { Button } from 'react-bootstrap';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import HeroBannerImage from "../../../assets/images/HeroBannerImage.png";
import HeroBannerImage1 from "../../../assets/images/HeroBannerImage1.png";
import HeroBannerImage2 from "../../../assets/images/HeroBannerImage2.png";
import HeroBannerImage3 from "../../../assets/images/HeroBannerImage3.png";
import HeroBannerImage4 from "../../../assets/images/HeroBannerImage4.png";
import HeroBannerImage5 from "../../../assets/images/HeroBannerImage5.png";
import HeroBannerImage6 from "../../../assets/images/HeroBannerImage6.png";

const ratingUrl = "https://www.capterra.com/reviews/246063/SafetyLoop?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge";

export default function HomePageHeroSection() {



    const heroTitles = [
        "Safety Compliance",
        "Compliance Simplified",
        "Compliance Solved",
        "Optimizing Safety",
        "Streamlined Safety",
        "Next-Gen Compliance",
        "Effortless Safety",
        "Compliance Mastery"
    ];
    
    const heroSubtexts = [
        "MADE EASIER",
        "STREAMLINED FOR YOU",
        "AT YOUR FINGERTIPS",
        "MADE SIMPLE",
        "INNOVATION AT WORK",
        "SIMPLIFIED SOLUTIONS",
        "EFFORTLESS COMPLIANCE",
        "SEAMLESS INTEGRATION"
    ];
    
    const heroContentTexts = [
        "Discover the future of safety with SafetyLoop's innovative software suite, blending advanced technology with years of expertise. Simplify and elevate your safety processes with our integrated, real-time solutions, designed to set new industry standards.",
        "Experience unmatched compliance support with SafetyLoop's dynamic software. Streamline your safety operations with our cutting-edge tools and make compliance a seamless part of your day-to-day business.",
        "Elevate your safety management with SafetyLoop. Our comprehensive software suite transforms complex compliance into straightforward tasks, powered by intelligent technology that anticipates your needs.",
        "Revolutionize your compliance management with SafetyLoop's all-in-one platform. Our advanced solutions bring simplicity and efficiency to your safety operations, ensuring you stay ahead in a constantly evolving industry.",
        "Achieve unparalleled safety standards with SafetyLoop's intelligent software. Our solutions integrate seamlessly into your workflow, delivering real-time insights and automation to elevate your compliance efforts.",
        "Transform your safety practices with SafetyLoop. Our innovative tools provide a holistic approach to compliance, empowering your team with the resources needed to maintain a safe and compliant environment.",
        "Step into the future of compliance with SafetyLoop. Our state-of-the-art software suite combines cutting-edge technology with user-friendly design, making safety management more intuitive and effective than ever before."
    ];
    

    // Function to randomly select a text from an array
    const getRandomText = (texts) => {
        return texts[Math.floor(Math.random() * texts.length)];
    };

    return (
        <div className="hero-section-container">
            <div className="text-content">
                <h2 className="hero-text">{getRandomText(heroTitles)}</h2>
                <p className="hero-subtext2">{getRandomText(heroSubtexts)}</p>
                <div className="hero-content">
                    <p className="hero-content-text">
                        {getRandomText(heroContentTexts)}
                    </p>
                </div>
                <div className="hero-button-row">
                    <a href="/contact">
                        <Button className="book-demo-btn">Book A Demo</Button>
                    </a>
                    <a href="/features">
                        <Button className="all-features-btn">See all features</Button>
                    </a>
                </div>
                <div className="rating-image" style={{ width: "130px", paddingTop: "30px" }}>
                    <a href={ratingUrl} target="blank"> <img border="0" src="https://assets.capterra.com/badge/3c084a585dbd8a18109cb49be84b6bf7.svg?v=2203506&p=246063" /> </a>
                </div>
            </div>
            
            <div className="image-content">
                <Carousel
                    showArrows={false}
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={3500}
                    stopOnHover={false}
                    swipeable={false}
                    transitionTime={300}
                    animationHandler="fade"
                >
                    <div>
                        <img src={HeroBannerImage} alt="hero-image" className="hero-image" />
                    </div>
                    <div>
                        <img src={HeroBannerImage1} alt="hero-image" className="hero-image" />
                    </div>
                    <div>
                        <img src={HeroBannerImage2} alt="hero-image" className="hero-image" />
                    </div>
                    <div>
                        <img src={HeroBannerImage3} alt="hero-image" className="hero-image" />
                    </div>
                    <div>
                        <img src={HeroBannerImage4} alt="hero-image" className="hero-image" />
                    </div>
                    <div>
                        <img src={HeroBannerImage5} alt="hero-image" className="hero-image" />
                    </div>
                    <div>
                        <img src={HeroBannerImage6} alt="hero-image" className="hero-image" />
                    </div>
                </Carousel>
            </div>
            
        </div>
    )
}
