import React, { useState } from "react";
import { Modal, Box, TextField, Button } from '@mui/material';
import axios from "../../../config/axiosConfig";
import { toast } from "react-toastify";
import Application from "../../../config/ApplicationWrapper";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddCustomAttributesModal = ({ open, handleClose, getPageDetails }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
        email: email,
      };

      let resultObj = await axios.post(`${Application.api_url}/api/custom_attributes`, formData);
      if (resultObj.data.success) {
        toast.success('Custom attributes added successfully');
        handleClose();
        getPageDetails();
      }
    } catch (err) {
      console.log("error==>", err);
      toast.error('Error adding custom attributes');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h2>Add Custom Attributes</h2>
        <p>These are things you want SafetyLoop to look for entered into the system by someone or something you want to flag.</p>
        <form onSubmit={handleSubmit}>
          <TextField
            label="First Name"
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            margin="normal"
            
          />
          <TextField
            label="Last Name"
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            margin="normal"
            
          />
          <TextField
            label="Phone Number"
            fullWidth
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            margin="normal"
            
          />
          <TextField
            label="Email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Save
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default AddCustomAttributesModal;