import React from "react";



export const TemplateQRPrint = React.forwardRef((props, ref) => {
  
     const url = "https://www.safetyloop.com/external_forms/enter_access_token?access_token="
     return (
      
        <div ref={ref} style={{ textAlign: "center"}} className="print-qr-body">
          <p
              style={{ fontWeight: "500", fontSize: "36px", color: "#080808", fontFamily: "Arial" }}
            >
              Scan now to start a new form.
            </p>
          <p style={{fontFamily: "Arial", fontSize: "22px" }}>
            <b>Form Name:</b> {props.pageData.external_form_template_datum.audit_template_name}
            
            
            <br />
            <b>Project Site / Group:</b> {props.pageData.external_form_template_datum.group_name}
          </p>
          
          <img alt="" src={props.pageData.qr_url} style={{ width: "40%"}}/>
          <br />
          <br />
          <br />
          <p>
            Having trouble scanning? Copy and paste this link into your browser:
            <br />
            
            <div style={{fontFamily:"Arial"}}>{url.toLowerCase()}+{props.pageData.external_form_template_datum.access_token}</div>
            
          </p>
          
        </div>
      
    );
  });