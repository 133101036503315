import '../../assets/styles/themes.scss'
import React, { useEffect, useState } from "react"
import axios from "axios"
import Application from "../../config/ApplicationWrapper"

const CheckoutOrderSummary = () => {
  const [subscriptionPlan, setSubscriptionPlan] = useState()

  useEffect(() => {
    const fetchSubscriptionPlan = async () => {
      try {
        const result = await axios.get(`${Application.api_url}/api/v2/subscription_plans`)

        if (result && result.data && result.data.data) {
          const subscriptionPlans = result.data.data
          const subscriptionPlanId = parseInt(new URLSearchParams(window.location.search).get("subscription_plan_id"))
          const foundPlan = subscriptionPlans.find((plan) => plan.id === subscriptionPlanId)
          if (foundPlan) {
            setSubscriptionPlan(foundPlan)
          } else {
            console.error('Invalid subscription_plan_id') // TODO: handle subscription_plan_id error in a better way
          }
        } else {
          console.error('Can not fetch subscription plans')
        }
      } catch (err) {
        console.error(err)
      }
    }

    fetchSubscriptionPlan()
  }, [])

  if (!subscriptionPlan) {
    return null
  }

  const pricePerLicense = subscriptionPlan.price
  const numberOfLicense = 1
  const totalPerMonth = pricePerLicense * numberOfLicense
  // debugger;
  return (
    <div className="orderSummary">
      <p className="title">Summary</p>
      <div className="order__detail">
        {
          subscriptionPlan.name === 'Safety Starter' ?
            <p className="key"> Current Plan</p>
          :
            <p className="key"> Current Plan + Free Trial!</p>
        }
        <p className="value">{subscriptionPlan.name}</p>
      </div>
      <div className="order__detail">
        <p className="key">Price per seat</p>
        <p className="value">{subscriptionPlan.currency}{pricePerLicense} CAD</p>
      </div>{" "}
      <div className="order__detail">
        <p className="key">Number of active user seats*</p>
        {subscriptionPlan.name === 'Enterprise' ? (
          "-"
        ) : (
          "1"
        )}
        {/* <p className="value">{numberOfLicense}</p> */}
      </div>
      <div className="order--separator"></div>
      <div className="order__detail">
        {
          subscriptionPlan.name === 'Safety Starter' ?
            <p className="key">Total due now</p>    
          :
            <p className="key">Total per Month, <u>after free trial.</u></p>
        }
        <p className="value">
          {subscriptionPlan.name == 'Free Plan' ? (
            "Free"
          ) : totalPerMonth && totalPerMonth !== 0 ? (
            `${subscriptionPlan.currency}${totalPerMonth} CAD`
          ) : (
            "Starting at $250/mo CAD"
          )}
        </p>
      </div>
    </div>
  )
}

export default CheckoutOrderSummary
