import consumer from './consumer';
import { toast } from "react-toastify";
import { User } from '../pages/HomePage/Login';

export function subscribeToUploadStatus(userId, uploadStatusId) {
  // console.log("UserId: ", userId);
  // console.log("UploadStatusId: ", uploadStatusId);

  return consumer.subscriptions.create({
    channel: 'UploadStatusChannel',
    user_id: userId,
    upload_status_id: uploadStatusId
  }, {
    connected() {
      console.log('Subscribed to upload status channel');
      // debugger;
    },

    disconnected() {
      console.log('Disconnected from upload status channel');
    },

    received(data) {
      console.log('******************************************************************');
      console.log("Received data: ", data)
      updateProgressBar(data)
    },
    rejected() {
      console.error('Subscription was rejected.');
    },

    error(error) {
      console.error('WebSocket error:', error);
    }
  });
}

function updateProgressBar(data) {
  const progressBar = document.getElementById('progress-bar');
  const progressBarPercentage = document.getElementById('progress-bar-percentage');

  if (progressBar && progressBarPercentage) {
    const progress = ((data.uploaded_files + data.failed_files) / data.total_files) * 100;
    progressBar.style.width = `${progress}%`;
    progressBar.setAttribute('aria-valuenow', progress);
    progressBarPercentage.innerText = `${progress.toFixed(2)}%`;

    if (progress === 100) {
      console.log('Upload DONE!!!');
      toast.success("Recent upload completed!");
      User.currentBulkUploadId = null;
      User.currentBulkUploadPercentage = 0;
    }
  } else {
    console.error('Progress bar elements not found in the DOM.');
  }
}
