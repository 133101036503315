import React, { useEffect, useReducer, useState } from "react";
import SideNav from "../../components/SideNav";
import Paywall from "./InventoryList/Paywall";
import { User } from "../../pages/HomePage/Login";
import Configuration from "../Inventory/Configuration";
import Inventory from "./InventoryList";
import { withRouter } from "react-router-dom";

const initialTabStatus = {
  inventory: true,
  config: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE":
      state[action.tab] = true;
      return { ...state };
    default:
      return state;
  }
};

const AssetTrackingTool = ({ history, location }) => {
  const [tabs, dispatch] = useReducer(reducer, initialTabStatus);
  const [queryParams, setQueryParams] = useState("");
  const [isShowPaywall, setIsShowPaywall] = useState(false);

  const handleUpdateTab = (tab) => {
    dispatch({ type: "UPDATE", tab: tab });
  };

  useEffect(() => {
    setIsShowPaywall(User.showPaywall());
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get("model");
    setQueryParams(paramValue);
  }, []);

  return (
    <React.Fragment>
      <div className="msmcontainer dashboard-container">
        <div className="row d-flex">
          <SideNav />

          <div className="content-wrap asset-tracking-wrap risk-assessment">
            <div className="title-tab-wrap">
              <h1>
                <i
                  style={{ paddingInlineStart: "5px", paddingRight: "5px" }}
                  className="fa fa-warehouse"
                ></i>
                <b>Inventory Management</b>
              </h1>
              {isShowPaywall ? (
                <Paywall />
              ) : (
                <>
                  <ul className="nav nav-tabs">
                    <li
                      className={queryParams ? "" : "active"}
                      onClick={(e) => {
                        handleUpdateTab("inventory");
                      }}
                    >
                      <a data-toggle="tab" href="#inventory">
                        Inventory Management
                      </a>
                    </li>
                    <li
                      className={queryParams == "config" ? "active" : ""}
                      onClick={(e) => {
                        handleUpdateTab("config");
                      }}
                    >
                      <a data-toggle="tab" href="#configuration">
                        Configuration
                      </a>
                    </li>
                  </ul>
                </>
              )}
            </div>
            { !isShowPaywall &&
              <div className="tab-content">
                <div
                  id="inventory"
                  className={
                    !queryParams ? "tab-pane fade in active" : "tab-pane fade in"
                  }
                >
                  <Inventory openUpdateModalHandler={setIsShowPaywall} />
                </div>
                <div
                  id="configuration"
                  className={
                    queryParams == "config"
                      ? "tab-pane fade in active"
                      : "tab-pane fade"
                  }
                >
                  {tabs.config || queryParams == "config" ? (
                    <Configuration />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            }
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AssetTrackingTool);
