import React from "react";
const user_icon = require("../../assets/images/Avatar_Icon.svg");

import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  Redirect,
  NavLink,
} from "react-router-dom";

import Login, { User } from "pages/HomePage/Login";

import { connect } from "react-redux";
import cx from "classnames";
import { setMobileNavVisibility } from "../../reducers/Layout";
import "assets/styles/header";
import "../../assets/styles/themes.scss";
const logo = require("../../assets/images/Logo.svg");
// PropTypes is a separate package now:
import PropTypes, { element } from "prop-types";
import HomePage from "../../pages/HomePage";
import axios, { post } from "axios";

import MenuIcon from "../../assets/images/menu-icon.svg";
import Profile from "../../assets/images/Profile.png";
import LogoutIcon from "../../assets/fonts/Logout.svg";
import companySettingIcon from "../../assets/fonts/companySetting.svg";
import Settings from "../../assets/fonts/Settings.svg";
import NotificationCenter from "./NotificationCenter";
const panelFooterStyle = {
  backgroundColor: "#282828",
};

class UserInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      CurrentUser: this.props.CurrentUser,
      redirectReferPage: false,
      user_role: User.role,
      user_data: "",
      profile_url: "",
      menuOpen: false,
      isOpenNotiDropdown: false,
      companyName: "",
    };

    // This binding is necessary to make `this` work in the callback
    this.updateCurrentUser = this.updateCurrentUser.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  handleToggleNotiDropdown() {
    this.setState((prevState) => ({
      isOpenNotiDropdown: !prevState.isOpenNotiDropdown,
      menuOpen: !prevState.isOpenNotiDropdown ? false : prevState.menuOpen,
    }));
  }

  handleToggleMenu() {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
      isOpenNotiDropdown: !prevState.menuOpen
        ? false
        : prevState.isOpenNotiDropdown,
    }));
  }

  componentDidMount() {
    let that = this;
    axios
      .get("/users/check_for_user", {})
      .then(function (response) {
        if (
          response.data.user &&
          (response.data.user.email || response.data.user.username)
        ) {
          // $("#app").data("user_data", response.data.user)
          // $("#app").data("profile_url", response.data.user_profile_url)
          // $("#app").data("user_role", response.data.user_role)
          // debugger;
          that.setState({
            email: response.data.user.email,
            first_name: response.data.user.first_name,
            last_name: response.data.user.last_name,
            username: response.data.user.username,
            employee_id: response.data.user.employee_id,
            profile_url: response.data.user_profile_url,
          });
          // that.user_profile_data()
        }

        that.setState({
          companyName: response.data.company.name,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange = (event) => {
    this.setState({
      text: event.target.value,
    });
  };

  changePage(newPage) {
    this.setState({
      page: newPage,
    });
  }

  fetchOneSignalId() {
    return window.OneSignal.getUserId(function (userId) {
      return userId;
    });
  }

  handleLogout(e) {
    e.preventDefault();
    const { history } = this.props;
    var that = this;
    let oneSignalId;

    try {
      oneSignalId = this.fetchOneSignalId();
    } catch (error) {
      oneSignalId = "";
    }

    $.ajax({
      type: "GET",
      url: `/users/sign_out?push_token=${oneSignalId}`,
      dataType: "json",
      error: function (error) {
        console.log(error);
      },
      success: function (res) {
        window.location.href = "/";
      },
    });
  }

  updateCurrentUser(email) {
    this.setState({
      currentUser: email,
    });
  }

  renderLinks() {
    if (
      User.role == "SuperAdmin" ||
      User.role == "Admin" ||
      User.role == "Manager" ||
      User.role == "Observer"
    ) {
      return (
        <li title="Company Profile">
          <NavLink
            to="/company_profile"
            href="/company_profile"
            id="company-profile"
            role="button"
          >
            Company Profile
          </NavLink>
        </li>
      );
    }
  }

  getCurrentUserInfo = () => {
    // debugger;
  };

  render() {
    const openNotification = () => {
      this.handleToggleNotiDropdown();
    };

    const openMenu = () => {
      this.handleToggleMenu();
    };

    const {
      user_role,
      CurrentUser,
      profile_url,
      first_name,
      last_name,
      menuOpen,
      isOpenNotiDropdown,
      companyName,
    } = this.state;
    if (this.state.redirectReferPage) {
      return <Redirect to="/" />;
    }

    return (
      <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
        <li className="nav-item dropdown submenu">
          <a
            aria-expanded="true"
            data-toggle="dropdown"
            href="#"
            style={{ padding: "0" }}
          >
            <div className="nav-user-info">
              <div className="nav-user-details">
                <h3>
                  {first_name} {last_name}
                </h3>
                <label>{user_role}</label>
              </div>
              <div className="avatar-sm" style={{ float: "right" }}>
                <img
                  alt="User"
                  src={profile_url ? profile_url : "/profile1.png"}
                  style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "13px",
                    borderRadius: "50%",
                  }}
                />
                <i
                  onClick={openNotification}
                  id="btnOpenNotification"
                  className="fas fa-bell nav-user-info__notification"
                ></i>
                <img
                  alt="User"
                  className="avatar-img rounded-circle"
                  id="top-header-icon"
                  src={MenuIcon}
                  style={{
                    width: "40px",
                    height: "40px",
                    padding: "10px",
                    backgroundColor: "#e4e4e4",
                  }}
                  onClick={openMenu}
                />
              </div>
            </div>
          </a>
          <ul
            id="ddropmenu"
            className={`dropdown-menu dropdown-user animated ${
              menuOpen ? "show" : ""
            }`}
            style={{
              width: "550px",
              position: "absolute",
              top: "100%",
              zIndex: "1000",
              float: "left",
              border: "0",
              borderRadius: "3px",
              boxShadow: "0 1px 11px rgba(0,0,0,.15)",
              minWidth: "10rem",
              padding: "0.5rem 0",
              margin: "13px -10px 0px 0px",
              fontSize: "1rem",
              color: "#212529",
              textAlign: "left",
              listStyle: "none",
              backgroundColor: "#fff",
              backgroundClip: "padding-box",
            }}
          >
            <div
              className="scroll-wrapper dropdown-user-scroll scrollbar-outer"
              style={{ position: "relative" }}
            >
              <div
                className="dropdown-user-scroll scrollbar-outer scroll-content"
                style={{
                  height: "auto",
                  marginBottom: "0px",
                  marginRight: "0px",
                }}
              >
                <li>
                  {User.isActiveSubscription && (
                    <>
                      <p className="menu-apps">APPS</p>
                      <ul id="ddropmenu" className="apps-details">
                        {(user_role == "Admin" ||
                          user_role == "SuperAdmin" ||
                          user_role == "Manager") && (
                          <li className="menu-apps-li">
                            <a href="/users-and-groups">
                              <i className="sb-icon fa fa-users"></i>
                              <h2>Groups</h2>
                            </a>
                          </li>
                        )}
                        <li className="menu-apps-li">
                          <a href="/rot_dashboard">
                            <i className="sb-icon fa fa-graduation-cap"></i>
                            <h2>Training</h2>
                          </a>
                        </li>
                        <li className="menu-apps-li">
                          <a href="/template/new_forms">
                            <i className="sb-icon fa fa-file-text"></i>
                            <h2>Forms</h2>
                          </a>
                        </li>
                        <li className="menu-apps-li">
                          <a href="/company_documents">
                            <i className="sb-icon fa fa-check-square-o"></i>
                            <h2>Documents</h2>
                          </a>
                        </li>
                        {(user_role == "Admin" ||
                          user_role == "SuperAdmin" ||
                          user_role == "Manager") && (
                          <>
                            <li className="menu-apps-li">
                              <a href="/risk-assessments">
                                <i className="sb-icon fa fa-area-chart"></i>
                                <h2>Risk Assessments</h2>
                              </a>
                            </li>
                            <li className="menu-apps-li">
                              <a href="/asset-tracking">
                                <i className="sb-icon fa fa-truck"></i>
                                <h2>Asset</h2>
                              </a>
                            </li>
                            {User.isInventoryEnabled() && (
                              <li className="menu-apps-li">
                                <a href="/inventory">
                                  <i className="sb-icon fa fa-warehouse"></i>
                                  <h2>Inventory</h2>
                                </a>
                              </li>
                            )}
                            {/*{User.isTimeTrackingEnabled() && (*/}
                            {/*    <li className="menu-apps-li">*/}
                            {/*      <a href="/time-tracking">*/}
                            {/*        <i className="sb-icon fa fa-warehouse"></i>*/}
                            {/*        <h2>Time Tracking</h2>*/}
                            {/*      </a>*/}
                            {/*    </li>*/}
                            {/*)}*/}
                            <li className="menu-apps-li">
                              <a href="/emergency-plans">
                                <i className="sb-icon fa fa-exclamation-triangle"></i>
                                <h2>Emergeny Plans</h2>
                              </a>
                            </li>
                            <li className="menu-apps-li">
                              <a href="/subcontractors">
                                <i className="sb-icon fa fa-address-card"></i>
                                <h2>Subcontractor</h2>
                              </a>
                            </li>
                          </>
                        )}
                        {(user_role == "Admin" ||
                          user_role == "SuperAdmin") && (
                          <>
                            <li className="menu-apps-li">
                              <a href="/qr_codes">
                                <i className="sb-icon fa fa-qrcode"></i>
                                <h2>QR Codes</h2>
                              </a>
                            </li>
                            <li className="menu-apps-li">
                              <a href="/release_notes">
                                <i className="sb-icon fa fa-sticky-note"></i>
                                <h2>Release Notes</h2>
                              </a>
                            </li>
                          </>
                        )}
                        <li className="menu-apps-li">
                          <a href="/actions">
                            <i className="sb-icon fas fa-running"></i>
                            <h2>Action</h2>
                          </a>
                        </li>
                      </ul>
                    </>
                  )}
                  <p className="menu-apps">MANAGE</p>
                  <ul id="ddropmenu" className="apps-details">
                    {User.isActiveSubscription && (
                      <>
                        <li className="menu-apps-li">
                          <a href="/user-profile">
                            <i className="sb-icon fa fa-user-circle"></i>
                            <h2>Settings</h2>
                          </a>
                        </li>
                        {(user_role == "Admin" ||
                          user_role == "SuperAdmin") && (
                          <li className="menu-apps-li">
                            <a href="/company_profile">
                              <i className="sb-icon fa fa-building"></i>
                              <h2>Company Profile</h2>
                            </a>
                          </li>
                        )}
                      </>
                    )}
                    {!User.isActiveSubscription && (
                      <li className="menu-apps-li">
                        <a href="/company_profile">
                          <i className="sb-icon fa fa-credit-card"></i>
                          <h2>Billing & Plan</h2>
                        </a>
                      </li>
                    )}
                    <li className="menu-apps-li" onClick={this.handleLogout}>
                      <a href="">
                        <img src="/Logout.svg" alt="" />
                        <h2>Logout</h2>
                      </a>
                    </li>
                  </ul>
                  {/* {(User.role == "Admin") &&
                                        <a ariaCurrent="page" className="dropdown-item" href="/company_profile" role="button" style={{
                                            lineHeight: "1.8",
                                            color: "#000",
                                            padding: "12px 24px",
                                            fontSize: "14px",
                                            display: "block",
                                        }}><img src={companySettingIcon} alt="" className='nav-list-icons'/> Company Profile</a>
                                    }
                                    <a className="dropdown-item" href="/user-profile" role="button" style={{
                                        lineHeight: "1.8",
                                        color: "#000",
                                        padding: "12px 24px",
                                        fontSize: "14px",
                                        display: "block",
                                    }}><img src={Settings} alt="" className='nav-list-icons'/> My Profile</a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="#" id="sign-out" onClick={this.handleLogout} style={{
                                        lineHeight: "1.8",
                                        color: "#000",
                                        padding: "12px 24px",
                                        fontSize: "14px",
                                        display: "block",
                                    }}><img src={LogoutIcon} alt="" className='nav-list-icons'/>  Logout</a> */}
                </li>
              </div>
              <div
                className="scroll-element scroll-x"
                style={{ display: "none" }}
              >
                <div className="scroll-element_outer">
                  <div className="scroll-element_size"></div>
                  <div className="scroll-element_track"></div>
                  <div
                    className="scroll-bar ui-draggable ui-draggable-handle"
                    style={{ width: "30px" }}
                  ></div>
                </div>
              </div>
              <div
                className="scroll-element scroll-y"
                style={{ display: "none" }}
              >
                <div className="scroll-element_outer">
                  <div className="scroll-element_size"></div>
                  <div className="scroll-element_track"></div>
                  <div
                    className="scroll-bar ui-draggable ui-draggable-handle"
                    style={{ height: "30px" }}
                  ></div>
                </div>
              </div>
            </div>
          </ul>
          <NotificationCenter isOpenNotiDropdown={isOpenNotiDropdown} />
        </li>
      </ul>
    );
  }
}

export default UserInfo;
