import { object, ref, string } from "yup"
import { useForm } from "react-hook-form"
import { useYupValidationResolver } from './hooks/useYupValidationResolver'
import React, { useState } from "react"
import CheckoutCollapse from "./CheckoutCollapse"
import CheckoutEInput from "./CheckoutEInput"
import CheckoutEButton from "./CheckoutEButton"
import CheckoutArrowRightLineIcon from "./CheckoutArrowRightLineIcon"
import axios from "axios"
import Application from "../../config/ApplicationWrapper"
import _ from "lodash"
import { withRouter } from 'react-router-dom'
import HCaptcha from '@hcaptcha/react-hcaptcha';


const AccountInfoSchema = object({
  first_name: string().required("First Name is required"),
  last_name: string().required("Last Name is required"),
  username: string().required("Username is required"),
  organization: string().required("Company name is required"),
  email: string().email("Must be a valid email").required("Email is required"),
  password: string()
    .required("Password is required")
    .min(6, "Must be at least 6 characters")
    .max(128, "Must be at most 128 characters"),
  password_confirmation: string()
    .required("Password Confirmation is required")
    .oneOf([ref("password")], "Password Confirmation does not match")
})

const CheckoutAccountInfo = ({
  openAccountInfo,
  isDone,
  isOpen,
  history
}) => {
  const {
    control,
    handleSubmit,
    // getValues,
    setError
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    resolver: useYupValidationResolver(AccountInfoSchema)
  })
  // console.log("=> Account Info", getValues())

  const [captchaToken, setCaptchaToken] = useState(null);



  const onSubmit = async (data) => {
    if (!captchaToken) {
      // Handle the error case where the hCaptcha has not been solved
      alert("Please solve the captcha");
      return;
    } else {

      $(".loading").show()
      

      try {
        const subscriptionPlanId = parseInt(new URLSearchParams(window.location.search).get("subscription_plan_id"))
        const result = await axios.post(`${Application.api_url}/api/v2/subscriptions`, {
          ...data,
          subscription_plan_id: subscriptionPlanId
        })

        if (result && result.data && result.data.success) {
          history.push('/login?from=checkout')
        }
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.message) {
          const { message: errorMessages } = err.response.data

          Object.entries(errorMessages).forEach(([field, messages]) => {
            setError(field, {
              type: 'server',
              message: `${_.startCase(field)} ${messages.join(', ')}`
            })
          })
        } else {
          console.error('Can not submit account information with error', err)
        }
      } finally {
        $(".loading").hide()
      }
    }
  }

  return (
    <div>
      {/* <CheckoutCollapse
        onClickCollapse={openAccountInfo}
        isOpen={isOpen}
        showSuffix={isDone}
        title="Account Information"
      > */}
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="checkoutRow">
            <div className="col col-2">
              <CheckoutEInput
                control={control}
                title= {
                  
                  <p>
                    First Name &nbsp;
                    <span className="text-danger">*</span>
                  </p>
                }
                placeholder="Enter First Name"
                type="text"
                rules={{ required: true }}
                name="first_name"
              />
              
            </div>
            <div className="col col-2">
              <CheckoutEInput
                control={control}
                title={
                  <p>
                    Last Name &nbsp;
                    <span className="text-danger">*</span>
                  </p>
                }
                placeholder="Enter Last Name"
                type="text"
                rules={{ required: true }}
                name="last_name"
              />
              
            </div>
            <div className="col col-2">
              <CheckoutEInput
                control={control}
                title= {
                  <p>
                     Username &nbsp;
                     <span className="text-danger">*</span>
                  
                  </p>
                }
                placeholder="Enter Username"
                type="text"
                rules={{ required: true }}
                name="username"
              />
              
            </div>
            <div className="col col-2">
              <CheckoutEInput
                control={control}
                title={
                  <p>
                    Email &nbsp;
                    <span className="text-danger">*</span>
                  </p>
                }
                placeholder="Enter Email"
                type="email"
                rules={{ required: true }}
                name="email"
              />
              
            </div>
            <div className="col col-2">
              <CheckoutEInput
                control={control}
                title={
                  <p>
                    Password &nbsp;
                    <span className="text-danger">*</span>
                  </p>
                }
                placeholder="Enter Password"
                type="password"
                rules={{ required: true }}
                name="password"
              />
            </div>
            <div className="col col-2">
              <CheckoutEInput
                control={control}
                title={
                  <p>
                    Password Confirmation &nbsp;
                    <span className="text-danger">*</span>
                  </p>
                }
                placeholder="Enter Password Confirmation"
                type="password"
                rules={{ required: true }}
                name="password_confirmation"
              />
            </div>
            <div className="col col-2">
              <CheckoutEInput
                control={control}
                title= {
                  
                  <p>
                    Company Name &nbsp;
                    <span className="text-danger">*</span>
                  </p>
                }
                placeholder="Enter Company Name"
                type="text"
                rules={{ required: true }}
                name="organization"
              />
              
            </div>
          </div>
          


          <div style={{ display: "flex", justifyContent: "" }}>

            <HCaptcha
              sitekey="806d128e-7999-4a5e-ad89-74a73e6ab9f0" // Replace with your hCaptcha site key
              onVerify={(token) => setCaptchaToken(token)}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "end" }}>

            
            
            <CheckoutEButton type="submit" variant="solid" style={{ width: 800 }}>
              Create free account &nbsp;
              
            </CheckoutEButton>
          </div>

          <div style={{ display: "flex", justifyContent: "end" }}>
            By creating an account you agree to SafetyLoop's &nbsp;
            <a href="/terms">Terms & Conditions</a> &nbsp;
            and &nbsp;
            <a href="/privacy">Privacy Policy</a>
          </div>
        </form>
      {/* </CheckoutCollapse> */}
      {/*{isSubmit && <p style={{ marginBottom: "16px" }}>{getValues().email}</p>}*/}
    </div>
  )
}

export default withRouter(CheckoutAccountInfo)
