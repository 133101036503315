import React from "react";
import { Nav, Navbar, Button, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import { NavLink, Link, Redirect } from "react-router-dom";

import Logo from "../../../javascript/assets/images/navbar-logo.png";
import PhoneIcon from "../../../javascript/assets/fonts/Phone.svg";




const Layout = () => {
  const goLogin = () => {
    return <Redirect to='/login' />
  }
  const handleLinkClick = () => {
    window.open('https://cor-academy.com', '_blank');
  }
  const handlePLLink = () => {
    // Additional logic can be added here if needed
    window.open("https://projectloop.com?from=SL", "_blank");
  };

  return (
    <div className="Header">
      <Navbar inverse collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">
              <img src={Logo} alt="..." style={{ width: "200px" }} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav className="menuitems">
            {/* <NavItem>
              <NavLink to="/product" activeClassName="active">Products</NavLink>
            </NavItem> */}

            <NavDropdown title="Features" id="safetytloop-features">
              <MenuItem href="/features/asset_management">Asset Management</MenuItem>
              <MenuItem href="/features/form_creation">Form Creation</MenuItem>
              <MenuItem href="/features/form_scheduling">Form Scheduling</MenuItem>
              <MenuItem href="/features/subcontractor_management">Subcontractor Management</MenuItem>
              <MenuItem href="/features/risk_assessment_management">Risk Assessment Management</MenuItem>
              <MenuItem href="/features/emergency_plan_management">Emergency Plan Management</MenuItem>
              <MenuItem href="/features/training_certificate">Training Certificate Management</MenuItem>
              <MenuItem href="/features/company_document_management">Company Document Management</MenuItem>
              <MenuItem href="/features/actions">Actions</MenuItem>
              <MenuItem href="/features/user_management">User Management</MenuItem>
              {/* <MenuItem href="/features/project_site_management">Project Site Management</MenuItem>
              <MenuItem href="/features/timetracking">Timetracking</MenuItem>
              <MenuItem href="/features/company_workflow_management">Company Workflow Management</MenuItem> */}
            </NavDropdown>

            {/* <NavItem>
              <NavLink to="/solution" activeClassName="active">Solutions</NavLink>
            </NavItem> */}
            <NavItem className="navvy">
              <NavLink to="/about" activeClassName="active">About Us</NavLink>
            </NavItem>

            {/* <NavItem className="navvy">
              <NavLink to="/teams" activeClassName="active">Our Team</NavLink>
            </NavItem> */}
            {/* <NavItem className="navvy">
              <NavLink to="/careers" activeClassName="active">Careers</NavLink>
            </NavItem> */}

            <NavItem className="navvy">
              <NavLink to="/pricing" activeClassName="active">Pricing</NavLink>
            </NavItem>

            <NavItem className="navvy">
              <NavLink to="/contact" activeClassName="active">Contact Us</NavLink>
            </NavItem>

            <NavItem className="navvy">
              <span onClick={handleLinkClick} activeClassName="active" style={{ "color": "#000" }}>
                COR Academy
                <img src="/main-images/COR-Academy-Badge.png" style={{ 'width': '50px', 'marginLeft': '5px' }} />
              </span>
            </NavItem>

            <NavItem className="navvy">
            <span onClick={handlePLLink} style={{ "color": "#000" }}>
              <a href="https://projectloop.com?from=SL" target="_blank" style={{ "color": "#000" }}>
                <img src="/main-images/pl-logo2.svg" style={{ 'width': '130px', 'marginLeft': '0px' }} />
                <i className="fa fa-external-link" style={{marginLeft:'5px', fontSize:'12px'}}></i>
              </a>
            </span>

            </NavItem>
          </Nav>
        </Navbar.Collapse>
        <div className="contact-dlts">
          <p className="phone-icon">
            <img src={PhoneIcon} alt="" />
          </p>
          <span
            className="phone-number">
            833-444-9876
          </span>
          <Link to="/login">
            <Button onClick={goLogin} style={{height:'45px', fontSize:'16px', backgroundColor:'rgb(64, 119, 151)', borderColor:'rgb(64, 119, 151)'}}>Login</Button>
          </Link>
        </div>
      </Navbar>
    </div>
  );
};

export default Layout;
