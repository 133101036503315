import React from 'react';

const Lfooter = () => {
  const footerStyle = {
    position: 'fixed',
    bottom: 0,
    width: '41.6%',
    height: '80px',
    backgroundColor: '#ccc',
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
  };

  return (
    <div style={footerStyle}>
      © 2024 SafetyLoop. All Rights Reserved.
    </div>
  );
};

export default Lfooter;