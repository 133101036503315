import React, { useState, useEffect, useLayoutEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const initialState = {
  template_id: "",
  group_id: "",
  subcontractor_id: "",
  auditor: "",
  hourly_chart: "",
  chart_type: "",
  date_range: "",
  start_date: "",
  end_date: "",
  group_type: ""
};
const data = { groups: [], auditors: [], form_types: [], date_ranges: [], subcontractors: [] };

const SearchFilter = ({ query, onFilterSelect, onFilterReset }) => {
  const [fields, setFields] = useState(initialState);
  const [filters, setFilters] = useState(data);
  const [userDetails, setUserDetails] = useState();
  const [minimize, setMinimize] = useState(false);

  useLayoutEffect(() => {
    let getUser = localStorage.getItem("user");
    getUser = JSON.parse(getUser);
    setUserDetails(getUser); 
    setMinimize(getUser.dashboard_positions && getUser.dashboard_positions.banner ?  JSON.parse(getUser.dashboard_positions.banner) : false)   
  }, [])

  useEffect(() => {
    async function fetchData() {
      let params = { group_id: fields['group_id'], subcontractor_id: fields['subcontractor_id'] };
      const result = await axios.get("analytics_dashboard/filters_data", {
        params: params,
      });
      setFilters(result.data);
      //setUserDetails(result.data ? result.data.user : "")
    }
    fetchData().then((r) => r);
  }, [query]);

  const generate_query_params = (value, field) => {
    setFields((prevState) => {
      return { ...prevState, [field]: value };
    });

    let params = { [field]: value, hourly_chart: "", chart_type: "" };
    let data = Object.assign({}, fields, params)

    if (field == "date_range" && value != "custom") {
      data["start_date"] = "";
      data["end_date"] = "";
    }

    reset_date_filter(field, value, data)
    return data;
  };

  const reset_date_filter = (field, value, params) => {
    let keys = Object.keys(params)
    let date_fields = ['start_date', 'end_date']

    if (keys.includes('date_range') && params['date_range'] != 'custom') {
      setFields((prevState) => {
        return { ...prevState, ['start_date']: '', ['end_date']: '' };
      });
    }
  }

  const handleChange = (event, field) => {
    let params = generate_query_params(event.value, field);

    if (field == "date_range" && event.value == "custom") {
      $(".custom-date-range").show();
    } else {
      if (params["date_range"] == "custom") {
        $(".custom-date-range").show();
      } else {
        $(".custom-date-range").hide();
      }

      if (field == "group_id") {
        if (event.group_type != '') {
          $(".subcontractor_data").show();
          params.group_type = 'GP'
          setFields((prevState) => {
            return { ...prevState, ['group_type']: 'GP' };
          });
        } else {
          $(".subcontractor_data").hide();
          params.group_type = ''
          setFields((prevState) => {
            return { ...prevState, ['subcontractor_id']: '', ['group_type']: '' };
          });
        }
        params.subcontractor_id = ''
      }
      onFilterSelect(params);
    }
  };

  const handleDate = (event, field) => {
    event.persist();
    let params = generate_query_params(event.target.value, field);

    if (params["start_date"] != "" && params["end_date"] != "") {
      if (params["end_date"] > params["start_date"]) {
        $(".date-error").hide();
        onFilterSelect(params);
      } else {
        $(".date-error").show();
      }
    }
  };

  const onResetFilter = () => {
    $(".custom-date-range, .subcontractor_data, .date-error").hide();
    setFields(initialState);
    onFilterReset(initialState);
  };

  const renderAuditorFilter = () => {
    return (
      <div className="col-md-4 form-group">
        <label className="control-label">Auditor</label>
        <Select
          name="auditor"
          value={fields["auditor"]}
          onChange={(e) => handleChange(e, "auditor")}
          options={filters.auditors}
          id="auditor"
          clearable={false}
        />
      </div>
    )
  }

  // const minimizeBanner = (value) => {
  //   let getUser = localStorage.getItem("user");
  //   getUser = JSON.parse(getUser);
  //   getUser.dashboard_positions.banner = value;
  //   setMinimize(value);
  //   let newObj = {
  //     user: {
  //       dashboard_positions: getUser.dashboard_positions,
  //     },
  //   };

  //   $.ajax({
  //     type: "PATCH",
  //     url: "/entity_management/update_dashboard_positions",
  //     dataType: "json",
  //     data: newObj,
  //     error: function (error) {
  //       console.log("error")
  //     },
  //     success: function (res) {
  //       console.log("updated successfully")
  //     },
  //   });

  //   localStorage.setItem("user", JSON.stringify({ ...userDetails, ...{ dashboard_positions: getUser.dashboard_positions } }))
  // }


  return (
    <React.Fragment>

      <div className="filter-wrap card filter-wrap-unique">
        {/* <div className={minimize ? "display-hide" : "forms-group-filter-banner"}>
          <div className="welcome-dash-message">
            <p>👋 <b>{userDetails && userDetails.first_name.charAt(0).toUpperCase() + userDetails.first_name.slice(1)} {userDetails && userDetails.last_name.charAt(0).toUpperCase() + userDetails.last_name.slice(1)}! </b>Welcome to <b>SafetyLoop</b></p>
            <div className="welcome-dash-sub">
              Hey! We are glad to see you again.
            </div>
          </div>
        </div>
        <div className={minimize ? "welcome-dash-message maximize" : "display-hide"}>
          <p>👋 <b>{userDetails && userDetails.first_name.charAt(0).toUpperCase() + userDetails.first_name.slice(1)} {userDetails && userDetails.last_name.charAt(0).toUpperCase() + userDetails.last_name.slice(1)}! </b>Welcome to <b>SafetyLoop</b></p>
          <div className="welcome-dash-sub">
            Hey! We are glad to see you again.
          </div>
        </div>
        <div className="minimize" onClick={(e) => { minimizeBanner(!minimize) }}>
          <p>{minimize ? "+" : "-"}</p>
        </div> */}
        <div className="row d-flex align-item-center dash search-dash">
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4 form-group">
                <label className="control-label">Group</label>
                <Select
                  name="group_id"
                  value={fields["group_id"]}
                  onChange={(e) => handleChange(e, "group_id")}
                  options={filters.groups}
                  id="group_id"
                  clearable={false}
                  defaultValue="-"
                />
              </div>
              <div className="subcontractor_data col-md-4 form-group" style={{ display: "none" }}>
                <label className="control-label">SubContractors</label>
                <Select
                  name="subcontractor_id"
                  value={fields["subcontractor_id"]}
                  onChange={(e) => handleChange(e, "subcontractor_id")}
                  options={filters.subcontractors}
                  id="subcontractor_id"
                  clearable={false}
                  defaultValue="-"
                />
              </div>

              <div className="col-md-4 form-group">
                <label className="control-label">Form Type</label>
                <Select
                  name="template_id"
                  value={fields["template_id"]}
                  onChange={(e) => handleChange(e, "template_id")}
                  options={filters.form_types}
                  id="template_id"
                  clearable={false}
                />
              </div>
              {fields["group_type"] == '' && renderAuditorFilter()}

            </div>
            <div className="mt-3 row">
              {fields["group_type"] != '' && renderAuditorFilter()}

              <div className="col-md-4 form-group">
                <label className="control-label">Date Range</label>
                <Select
                  name="date_range"
                  value={fields["date_range"]}
                  onChange={(e) => handleChange(e, "date_range")}
                  options={filters.date_ranges}
                  id="date_range"
                  clearable={false}
                  defaultValue="-"
                />
              </div>
              <div
                className="custom-date-range col-md-4 form-group"
                style={{ display: "none" }}
              >
                <label className="control-label">Start Date</label>
                <input
                  className="Select-control"
                  type="date"
                  id="start_date"
                  name="start_date"
                  value={fields["start_date"]}
                  onChange={(e) => handleDate(e, "start_date")}
                ></input>
                <span
                  className="date-error error-msg"
                  style={{ display: "none" }}
                >
                  Start date should be less than end date
                </span>
              </div>
              <div
                className="custom-date-range col-md-4 form-group"
                style={{ display: "none" }}
              >
                <label className="control-label">End Date</label>
                <input
                  className="Select-control"
                  type="date"
                  id="end_date"
                  name="end_date"
                  value={fields["end_date"]}
                  onChange={(e) => handleDate(e, "end_date")}
                ></input>
              </div>
            </div>
          </div>

          <div className="col-md-3 btn-wrap" style={{ marginTop: "-66px" }}>
            <div className="filter-button" id="home-reset-filter">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => onResetFilter(e)}
              >
                Reset Filter
              </button>
            </div>
            <div style={{ marginLeft: "10px", display: "inline-block" }}>
              <i
                style={{ fontSize: "20px", color: "#407797", float: "right" }}
                className="fa fa-print"
                id="print-page"
                aria-hidden="true"
                onClick={() => window.print()}
              ></i>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchFilter;