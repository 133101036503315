import { Switch, FormControl, Select, MenuItem, Pagination } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment"; 
import _ from "lodash";
import SearchIcon from "../../../../assets/images/searchbar.svg" 
import { withRouter } from "react-router-dom";
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";
import { toast } from "react-toastify";
import Loader from "../../../../components/NewLoader";
import MultiSelect from "./add";

const PickerList = ({ history }) => {
    const mounted = useRef(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageDetails, setPageDetails] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [count, setCount] = useState(0);
    const [name, setSearchByName] = useState();
    const [date, setSearchByDate] = useState();
    const [users, setUserList] = useState();

    useEffect(() => {
        getPageDetails()
        userDetails()
    }, [page, limit])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            mounted.current && getPageDetails()
            mounted.current = true;
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name, date])


    const getPageDetails = async () => {
        try {
            await axios.get(`${Application.api_url}/api/v2/inventory_pickers`, {
                params: {
                    page: page,
                    limit: limit,
                    user_full_name_cont: name,
                    user_address_cont: date
                },
            }).then((res) => {
                if (res.data && res.data.success === true) {
                    let details = res.data;
                    console.log(details.data)
                    setPageDetails(details ? details.data : [])
                    setPageCount(
                        details && details.metadata ? details.metadata.pages : 0
                    );
                    setCount(
                        details && details.metadata ? details.metadata.count : 0
                    );
                    setLoading(false)
                }
            }).catch((err) => console.log("err=>", err))
        } catch (err) {
            console.log("Err==>", err)
        }
    }

    async function userDetails() { 
        const users = await axios.get(`${Application.api_url}/api/v2/inventory_pickers/available_users`);
        if (users.status == 200 && users.data.success === true) {
            console.log("result.data===>", users.data.data) 
            var details = users.data;
            setUserList(details.data);
        }
    }

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const archive = async (data) => {
        try {
            await axios.put(`${Application.api_url}/api/v2/inventory_pickers/${data.identifier}`, { is_active: !data.is_active }).then((result) => {
                if (result.data && result.data.success === true) {
                    toast.success(data.is_active ? 'Deactivated Successfully' : 'Activated Successfully')
                    getPageDetails()
                }
            }).catch((err) => console.log("err=>", err))
        } catch (err) {
            console.log("err==>", err)
        }
    }

    const remove = async (data) => {
        try {
            await axios.delete(`${Application.api_url}/api/v2//inventory_pickers/${data.identifier}`).then((result) => {
                if (result.data && result.data.success === true) {
                    toast.success('Deleted Successfully')
                    getPageDetails()
                }
            }).catch((err) => console.log("err=>", err))
        } catch (err) {
            console.log("err==>", err)
        }
    }

    const clearFilter = () => {
        setSearchByName("");
        setSearchByDate(""); 
    }

    const close = async (data) => {
        if (data) {
            let userIds = _.map(data, "identifier") 
            await axios.post(`${Application.api_url}/api/v2/inventory_pickers/bulk_create`, {user_identifiers: userIds}).then((result) => {
                if (result.data && result.data.success === true) {
                    toast.success(`Created Successfully`)
                    setShow(false)
                    getPageDetails()
                }
            }).catch((error) => {
                error.response.data.message.map((msg) => {
                    toast.error(msg)
                });
            })
        } else {
            setShow(false)
        }
    }

    return (
        <div className="supplier-list">
            <div className="title">
                <h4>Picker List <span>{count}</span></h4>
                <div className="add-new-btn">
                    <button onClick={() => setShow(true)}>+ Add Picker List</button>
                </div>
            </div>
            <div className="fields-setting">
                <div className="asset-active-filters">
                    Active Filters :
                    <span className="active-filters">
                        <span>Picker Name :</span>
                        <span className="selected">{name ? name : "All"}</span>
                        <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
                    </span> 
                    <span className="active-filters">
                        <span>Address :</span>
                        <span className="selected">{date ? date : "All"}</span>
                        <span className={date ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByDate("") }}>X</span>
                    </span>
                    <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                        Clear All
                    </span>
                </div>
            </div>
            <div className="page-content">
                <table>
                    <thead>
                        <tr>
                            <th className="divider">
                                Picker Name
                                {pageDetails.length > 0 &&
                                    <div className="search-col">
                                        <input type="text" name="search" id="search" value={name} className="search-btn" onChange={(e) => { setSearchByName(e.target.value) }} />
                                        <img src={SearchIcon} alt="Search Icon" className="search-icon" placeholder="Search" />
                                    </div>
                                }
                            </th>  
                            <th className="divider">Contact Information</th>
                            <th className="divider">
                                Address
                                {pageDetails.length > 0 &&
                                    <div className="search-col">
                                        <input type="text" name="search" id="search" value={date} className="search-btn" placeholder="All" onChange={(e) => { setSearchByDate(e.target.value) }} />
                                        <img src={SearchIcon} alt="Search Icon" className="search-icon" placeholder="Search" />
                                    </div>
                                }
                            </th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading &&
                            pageDetails.length > 0 &&
                            pageDetails.map((val, index) => {  
                                return (
                                    <tr>
                                        <td>{val.user ? val.user.full_name : ""}</td>
                                        <td>
                                            <p className="action-by">{val.user ? val.user.phone_number : ""}</p>
                                            <p className="action-time">{val.user ? val.user.email : ""}</p>
                                        </td> 
                                        <td>{val.user ? val.user.address : ""}</td>
                                        <td>
                                            <i class="fa fa-minus-circle" aria-hidden="true" style={{color:"red"}} onClick={(e) => { remove(val) }} ></i>
                                            <Switch
                                                checked={val.is_active}
                                                onClick={(event) => archive(val)}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        <Loader pageDetails={pageDetails} loading={loading} btnName={"+ Add Picker List"} open={() => { setShow(true) }} />
                    </tbody>
                </table>
            </div>
            <div className="page-limits">
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={limit}
                        onChange={(e) => {
                            setPage(1);
                            setLoading(true);
                            setLimit(e.target.value);
                        }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                    </Select>
                </FormControl>
                <Pagination
                    count={pageCount || 1}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangePage}
                />
            </div> 
            {show && users.length > 0 &&
                <MultiSelect show={show} closeup={close} header={"Add Picker"} details={users} rightSide={[]} />
            }
        </div>
    )

}

export default withRouter(PickerList);