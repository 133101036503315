import React from 'react';
import Northern from "../../../../javascript/assets/images/Northern.svg";
import Astro from "../../../../javascript/assets/images/Astro.svg";
import Protech from "../../../../javascript/assets/images/Protech.svg";
import BOT from "../../../../javascript/assets/images/BOT.svg";
import Dayson from "../../../../javascript/assets/images/Dayson.svg";
import Instuform from "../../../../javascript/assets/images/Instuform.svg";
import TWBecker from "../../../../javascript/assets/images/TWBecker.svg";
import Ambler from "../../../../javascript/assets/images/Ambler.svg";
import State from "../../../../javascript/assets/images/State.svg";
import TMG from "../../../../javascript/assets/images/TMG.svg";
import Empipe from "../../../../javascript/assets/images/Empipe.svg";
import Newman from "../../../../javascript/assets/images/Newman.svg";

export default function CompaniesSliderSection() {
    return (
        <div>
            <div class="services-section">
            
                <div class="service-card">
                    <img src="/main-images/hp/contractors.png" alt="Service Icon" />
                    <h3>Contractor Management</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/copy.png" alt="Service Icon" />
                    <h3>Document Management</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/risk-management.png" alt="Service Icon" />
                    <h3>Incident Management</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/corrective.png" alt="Service Icon" />
                    <h3>Inspection Management</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/quality-control.png" alt="Service Icon" />
                    <h3>Training Record Management</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/settings.png" alt="Service Icon" />
                    <h3>Customizable automations</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/identification.png" alt="Service Icon" />
                    <h3>Hazard Management</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/action.png" alt="Service Icon" />
                    <h3>Corrective Actions</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/inventory.png" alt="Service Icon" />
                    <h3>Inventory Management</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/excavator.png" alt="Service Icon" />
                    <h3>Asset Management</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/compliant.png" alt="Service Icon" />
                    <h3>Onboarding Orientation</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/execute.png" alt="Service Icon" />
                    <h3>Task Management</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/emergency.png" alt="Service Icon" />
                    <h3>Emergency Management</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/qr-code.png" alt="Service Icon" />
                    <h3>QR Code Management</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/connection.png" alt="Service Icon" />
                    <h3>Data & Integrations</h3>
                </div>
                <div class="service-card">
                    <img src="/main-images/hp/data-analytics.png" alt="Service Icon" />
                    <h3>Analytics</h3>
                </div>
            
            </div>
            <div>
                <h2 className="sl-slider-text">Preferred by experts in the field worldwide</h2>
            </div>
            <div className="sl-slider">
                <div className="sl-slide-track">
                    <div className="slide">
                        <img src={State} alt="State-Logo" height="100" width="250" />
                    </div>
                    <div className="slide">
                        <img src={Astro} alt="Astro-Logo" height="100" width="250" />
                    </div>
                    <div className="slide">
                        <img src={Protech} alt="Protech-Logo" height="100" width="250" />
                    </div>
                    <div className="slide">
                        <img src={BOT} alt="BOT-Logo" height="100" width="250" />
                    </div>
                    <div className="slide">
                        <img src={Dayson} alt="Dayson-Logo" height="100" width="250" />
                    </div>
                    <div className="slide">
                        <img src={Instuform} alt="Instuform-Logo" height="100" width="250" />
                    </div>
                    <div className="slide">
                        <img src={TWBecker} alt="TWBecker-Logo" height="100" width="250" />
                    </div>
                    <div className="slide">
                        <img src={Ambler} alt="Ambler-Logo" height="100" width="250" />
                    </div>
                    <div className="slide">
                        <img src={Northern} alt="Northern-Logo" height="100" width="250" />
                    </div>
                    <div className="slide">
                        <img src={TMG} alt="TMG-Logo" height="100" width="250" />
                    </div>
                    <div className="slide">
                        <img src={Empipe} alt="Empipe-Logo" height="100" width="250" />
                    </div>
                    <div className="slide">
                        <img src={Newman} alt="Newman-Logo" height="100" width="250" />
                    </div>
                </div>
            </div>
        </div>
    )
}
