import React, { useState, useEffect } from "react";
import { TableContainer, Paper } from '@mui/material';
import Cookies from "js-cookie";
import DeleteBtn from "../../../../assets/fonts/Delete.svg";
import EditBtn from "../../../../assets/fonts/edit.svg";
import Filter from "../../../../assets/images/Filter.png";
import Folder from "../../../../assets/fonts/Folder.svg";
import Unstar from "../../../../assets/fonts/unstar.svg";
import Stared from "../../../../assets/fonts/stared.svg";
import PNGIcon from "../../../../assets/fonts/png.png";
import JPGIcon from "../../../../assets/fonts/jpg.png";
import DOCSIcon from "../../../../assets/fonts/docs.png";
import XLSXIcon from "../../../../assets/fonts/xlsx1.png";
import MP4Icon from "../../../../assets/fonts/mp4.png";
import MP3Icon from "../../../../assets/fonts/mp3.png";
import ZIPIcon from "../../../../assets/fonts/zip.png";
import PPTIcon from "../../../../assets/fonts/ppt.png";
import XLSIcon from "../../../../assets/fonts/xls.png";
import PDFIcon from "../../../../assets/fonts/pdf.png";
import URLCopy from "../../../../assets/fonts/URLCopy.svg";
import EmptyTable from "../../../../assets/images/empty-assets.svg";
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";
import EditDocs from "../Popups/Docs";
import { toast } from "react-toastify";

const ListView = ({ pageDetails, deleteFolder, changeiDirectory, pageData, update, changeSorting, stared, searching, searchByName, searchByType, searchByCreatedBy, searchByUpdatedAt, breadcrumb, view }) => {
    const [sorting, setSorting] = useState(false);
    const [sortedBy, setSortedBy] = useState("file_name");
    const imageFormat = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
    const pdfFormat = /(\.pdf)$/i;
    const [openview, setOpenView] = useState(false);
    const [selectedData, setSelectedData] = useState();
    const [docPopup, setDocPopup] = useState(false);
    const [editData, setEditData] = useState(false);

    const NoAssetsFound = () => (
        <div className="no-assets" style={{ textAlign: "center" }}>
            <img src={EmptyTable} />
            <p>
                <b>No Data</b>
            </p>
        </div>
    );

    const openDocPopup = (data) => {
        setDocPopup(true);
        if (data) {
            setEditData(data);
        }
    };

    const closePopup = (data) => {
        setDocPopup(false);
        setEditData(false)
        if (data) {
            toast.success("Updated Successfully");
            update(Date.now());
        }
    };

    const hanldeClick = async (val) => {
        if (val.doc_type == "folder") {
            changeiDirectory(val)
        } else {
            // setSelectedData(val.slug)
            // setOpenView(true) 
            const data = await axios.get(`${Application.api_url}/api/v2/company_documents/${val.slug}`);
            if (data.status == 200 && data.data.success === true) {
                let values = data.data.data;
                if (values.file && values.file.url) {
                    window.open(values.file.url, "_blank")
                }
            }
        }
    }

    const FileType = ({ item }) => {
        let fileIcon;
        if (item.doc_type == "folder") {
            fileIcon = Folder;
        } else if (item && (item.name.includes('png') || item.name.includes('PNG'))) {
            fileIcon = PNGIcon
        } else if (item && (item.name.includes('jpg') || item.name.includes('jpeg'))) {
            fileIcon = JPGIcon
        } else if (item && item.name.includes('pdf')) {
            fileIcon = PDFIcon
        } else if (item && (item.name.includes('doc') || item.name.includes('docx'))) {
            fileIcon = DOCSIcon
        } else if (item && item.name.includes('xlsx')) {
            fileIcon = XLSXIcon
        } else if (item && (item.name.includes('xls') || item.name.includes('csv'))) {
            fileIcon = XLSIcon
        } else if (item && item.name.includes('mp4')) {
            fileIcon = MP4Icon
        } else if (item && item.name.includes('mp3')) {
            fileIcon = MP3Icon
        } else if (item && item.name.includes('zip')) {
            fileIcon = ZIPIcon
        } else if (item && (item.name.includes('ppt') || item.name.includes('pptx'))) {
            fileIcon = PPTIcon
        } else {
            fileIcon = DOCSIcon
        }

        return (
            <img src={fileIcon} alt="image" style={{ width: "40px", marginRight: "10px" }} />
        )

    }

    const onDragStart = (event, id) => {
        event.dataTransfer.setData('id', id);
    };

    const onDragOver = (event) => {
        event.preventDefault();
    };

    const onDrop = async (event, targetId) => {
        const draggedId = event.dataTransfer.getData('id');
        // const draggedItem = pageDetails.find(item => item.id == draggedId);
        // const remainingItems = pageDetails.filter(item => item.id != draggedId);
        const targetItem = pageDetails.find(item => item.id == targetId); 

        const formData = new FormData();
        formData.append("doc_name", "Testing");
        formData.append("parent_id", targetId);
        if (targetId && draggedId && targetItem.doc_detail && targetItem.doc_detail.doc_type != "file") {
            const result = await axios({
                method: "PUT",
                url: `${Application.api_url}/api/v2/asset_trackings/${pageData.id}/documents/${draggedId}`,
                data: formData,
            });

            if (result && result.status == 200) {
                toast.success("Moved Successfully");
                update(Date.now());
            }
        }
    };
    return (
        <TableContainer component={Paper}>
            <table className="table table-striped">
                <thead>
                    <tr style={{ height: "60px" }} className="risk-assessment-divider">
                        {/* <th scope="col" className="risk-assessment-divider"></th> */}
                        <th scope="col" className="risk-assessment-divider">
                            <div
                                className="d-flex justify-content-between align-item-center"
                            // onClick={(e) => {
                            //     let sort = sortedBy == "name" ? !sorting : true;
                            //     setSorting(sort);
                            //     setSortedBy("name")
                            //     changeSorting(sort, "file_name")
                            // }}
                            >
                                Name
                                {/* <img src={Filter} alt="" className={sortedBy == "name" && sorting ? "up-arrow" : ""} /> */}
                            </div>
                            {/* <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control assessment-name"
                                    placeholder="Search"
                                    aria-label="name"
                                    aria-describedby="basic-addon1"
                                    name="searchByName"
                                    onChange={(e) => {
                                        searching("name", e.target.value)
                                    }}
                                    value={searchByName}
                                />
                                <div className="input-group-addon">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div> */}
                        </th>
                        <th scope="col" className="risk-assessment-divider">
                            <div
                                className="d-flex justify-content-between align-item-center"
                            // onClick={(e) => {
                            //     let sort = sortedBy == "type" ? !sorting : true;
                            //     setSorting(sort);
                            //     setSortedBy("type")
                            //     changeSorting(sort, "doc_type")
                            // }}
                            >
                                Type
                                {/* <img src={Filter} alt="" className={sortedBy == "type" && sorting ? "up-arrow" : ""} /> */}
                            </div>
                            {/* <div className="input-group">
                                <select
                                    className="form-control risk-type"
                                    onChange={(e) => {
                                        searching("type", e.target.value)
                                    }}
                                    value={searchByType}
                                >
                                    <option value="">All</option>
                                    <option value="folder">Folder</option>
                                    <option value="file">File</option>
                                </select>
                            </div> */}
                        </th>
                        <th scope="col" className="risk-assessment-divider">
                            <div
                                className="d-flex justify-content-between align-item-center"
                            // onClick={(e) => {
                            //     let sort = sortedBy == "created_by" ? !sorting : true;
                            //     setSorting(sort);
                            //     setSortedBy("created_by");
                            //     changeSorting(sort, "created_by")
                            // }}
                            >
                                Created By
                                {/* <img src={Filter} alt="" className={sortedBy == "created_by" && sorting ? "up-arrow" : ""} /> */}
                            </div>
                            {/* <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control assessment-name"
                                    placeholder="Search"
                                    aria-label="group"
                                    id="created_by"
                                    aria-describedby="basic-addon1"
                                    name="created_by"
                                    onChange={(e) => {
                                        searching("created_by", e.target.value);
                                    }}
                                    value={searchByCreatedBy}
                                />
                                <div className="input-group-addon">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div> */}
                        </th>
                        <th scope="col" className="risk-assessment-divider">
                            <div
                                className="d-flex justify-content-between align-item-center"
                            // onClick={(e) => {
                            //     let sort = sortedBy == "updated_at" ? !sorting : true;
                            //     setSorting(sort);
                            //     setSortedBy("updated_at")
                            //     changeSorting(sort, "updated_at")
                            // }}
                            >
                                Updated At
                                {/* <img src={Filter} alt="" className={sortedBy == "updated_at" && sorting ? "up-arrow" : ""} /> */}
                            </div>
                            {/* <div className="input-group">
                                <input
                                    type="date"
                                    className="form-control assessment-name"
                                    placeholder="Search"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="updated_at"
                                    value={searchByUpdatedAt}
                                    onChange={(e) => {
                                        searching("updated_at", e.target.value);
                                    }}
                                />
                            </div> */}
                        </th>
                        {!view && <th scope="col" className="table-action">Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {pageDetails &&
                        pageDetails.length > 0 &&
                        pageDetails.map((val, index) => {
                            
                            return (
                                <tr key={index} onDragOver={(e) => onDragOver(e)} onDrop={(e) => onDrop(e, val.id)}>
                                    {/* <td className="ui-droppable">
                                        <img src={val.is_starred ? Stared : Unstar} alt="" className="cursor-pointer" onClick={(e) => { stared(val.is_starred, val.slug) }} />
                                    </td> */}
                                    <td className="alerts-name" style={{ position: "relative" }} draggable="true" onDragStart={(e) => onDragStart(e, val.id)}>
                                        <p onClick={(e) => { hanldeClick(val) }} role="button" className="pf-name-list">
                                            <FileType item={val} />{val.name || val.doc_name}
                                        </p>
                                    </td>
                                    <td className="ui-droppable">
                                        {val.doc_type == "folder" ? "Folder" : "File"}
                                    </td>
                                    <td className="ui-droppable">
                                        <p className="action-by">{val.uploaded_by.full_name}</p>
                                        <p className="action-time">{val.created_at}</p>
                                    </td>
                                    <td className="ui-droppable">
                                        {val.created_at}
                                    </td>
                                    {!view &&
                                        <td
                                            className="table-action ui-droppable align-center"
                                        >

                                            {val.is_asset_doc != true &&
                                                <>
                                                    <img src={DeleteBtn} alt="" className="cursor-pointer" onClick={(e) => {
                                                        deleteFolder(val.id);
                                                    }} />
                                                    <img src={EditBtn} alt="" className="cursor-pointer" onClick={(e) => {
                                                        openDocPopup(val)
                                                    }} />
                                                    <img src={URLCopy} alt="" className="cursor-pointer" onClick={(e) => {
                                                        navigator.clipboard.writeText(`${window.location.origin}/company_documents/${val.slug}`)
                                                    }} />
                                                </>
                                            }
                                        </td>
                                    }
                                </tr>
                            )
                        })}
                    {pageDetails && pageDetails.length == 0 &&
                        <tr>
                            <td colspan={6}>
                                <NoAssetsFound />
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
            {editData && (
                <EditDocs
                    show={docPopup}
                    closepopup={closePopup}
                    data={editData}
                    parentId={pageData.id}
                />
            )}
        </TableContainer>
    )
}

export default ListView;